import React, { useState } from 'react';

const Swiper_Navigation_Roundbutton = ({boxicon_name, handle_onclick}) => {
    const [ hover, setHover ] = useState(false);

    return (
        <div className='w-12 h-12 bg-white rounded-full border border-gray-300 drop-shadow flex justify-center items-center cursor-pointer' 
        onMouseEnter={() => setHover(true)} onMouseLeave={() => setHover(false)} onClick={handle_onclick}>
            <box-icon name={boxicon_name} color={hover ? '#c29958' : '#222222'}></box-icon>
        </div>
    )
}

export default Swiper_Navigation_Roundbutton