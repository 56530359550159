import React, { useEffect, useRef, useState } from 'react';
import Header from '../../Components/Header/Header';
import Footer from '../../Components/Footer/Footer';
import cover from '../../../assets/cover/cover_admin.png';
import Admin_add_button from '../../Components/Button/Admin_add_button';
import Modal_add_image from '../../Components/Modal/Modal_add_image';
import Modal_add_text from '../../Components/Modal/Modal_add_text';
import Modal_add_date from '../../Components/Modal/Modal_add_date';
import Modal_Admin_KM_add_detail from './Modal_Admin_KM_add_detail';
import KM_paragraph from '../../KM/KM_paragraph';
import ModalConfirmForm from '../../Components/Modal/ModalConfirmForm/ModalConfirmForm';
import RoundFullButton from '../../Components/Button/RoundFullButton';
import { useNavigate, useParams } from 'react-router-dom';
import axios from 'axios';
import { php_list } from '../../../php/php_list';
import { UilSearch } from '@iconscout/react-unicons';
import { motion, useAnimationControls } from "framer-motion";
import Pagination from '../../Components/Pagination/Pagination';

const Example_image = ({url, handle_delete, handle_change}) => {
    const [ hover, setHover ] = useState(false);

    return (
        <div className='relative w-full h-auto min-h-96 cursor-pointer' onMouseEnter={() => setHover(true)} onMouseLeave={() => setHover(false)}>
            {
                (handle_delete) ? 
                    <div className='absolute w-fit h-fit top-0 right-0 w-fit h-fit mt-2 mr-2 scale-100 hover:scale-150 transition-transform duration-300 z-30' onClick={handle_delete}>
                        <box-icon name='x' color='#ffffff' ></box-icon>
                    </div>
                :
                    null
            }

            <div className='absolute top-0 left-0 w-full h-full flex justify-center items-center' onClick={handle_change}>
                <p className={'notosan-reg text-white transition-opacity duration-300 z-20 ' + (hover ? 'opacity-100' : 'opacity-0')}>เปลี่ยนรูปข่าว</p>
            </div>

            <div className={'absolute top-0 left-0 w-full h-full bg-black rounded-xl z-10 transition-opacity duration-300 ' + (hover ? 'opacity-70' : 'opacity-0')}></div>
            <img src={url} className='relative w-full h-auto min-h-96 max-h-svh object-cover rounded-xl'/>
        </div>
    )
}

const Example_title = ({title, handle_change}) => {
    const [ hover, setHover ] = useState(false);

    return (
        <div className='relative w-full h-fit py-3 cursor-pointer' onMouseEnter={() => setHover(true)} onMouseLeave={() => setHover(false)} onClick={handle_change}>
            <div className='absolute top-0 left-0 w-full h-full flex justify-center items-center'>
                <p className={'notosan-reg text-white transition-opacity duration-300 z-20 ' + (hover ? 'opacity-100' : 'opacity-0')}>เปลี่ยนหัวข้อข่าว</p>
            </div>
            <div className={'absolute top-0 left-0 w-full h-full bg-black rounded-sm z-10 transition-opacity duration-300 ' + (hover ? 'opacity-70' : 'opacity-0')}></div>
            <h3 className='text-web-gold text-3xl sm:text-4xl notosan-med'>{title}</h3>
        </div>
    )
}

const Example_date = ({date, handle_change}) => {
    const options = { year: 'numeric', month: 'long', day: 'numeric', timeZone: 'Asia/Bangkok' };
    const [ hover, setHover ] = useState(false);

    return (
        <span className='relative w-fit h-12 min-h-12 inline-block cursor-pointer' onMouseEnter={() => setHover(true)} onMouseLeave={() => setHover(false)}
        onClick={handle_change}>
            <span className={'absolute top-0 left-0 w-full h-full flex justify-center items-center text-white z-20 ' + (hover ? 'opacity-100' : 'opacity-0')}>เปลี่ยน</span>
            <span className={'absolute top-0 left-0 w-full h-full transition-color duration-300 z-10 ' + (hover ? 'bg-black/70' : 'bg-transparent')}></span>
            <span className='w-full h-12 min-h-12 notosan-med text-web-gold z-0'>โพสต์วันที่ : {new Date(date).toLocaleDateString('th-TH', options)}</span>
        </span>
    )
}

const Example_detail_title = ({detail, handle_click, handle_close}) => {
    const [ hover, setHover ] = useState(false);

    return (
        <div className='relative w-full h-fit' onMouseEnter={() => setHover(true)} onMouseLeave={() => setHover(false)}>
            <div className='absolute w-fit h-fit top-0 right-0 w-fit h-fit scale-100 hover:scale-150 transition-transform duration-300 z-20 cursor-pointer'
            onClick={handle_close}>
                <box-icon name='x' color='#ffffff' ></box-icon>
            </div>

            <div className={'absolute top-0 left-0 w-full h-full bg-black/70 flex justify-center items-center cursor-pointer transition-opacity duration-300 ' + (hover ? 'opacity-100' : 'opacity-0')}
            onClick={handle_click}>
                <p className='notosan-reg text-white'>เปลี่ยน</p>
            </div>

            <h3 className='text-web-gold text-2xl notosan-med mt-16 mb-6'>{detail.topic}</h3>
        </div>
    )
}

const Example_detail_detail = ({detail, handle_click, handle_close}) => {
    const [ hover, setHover ] = useState(false);

    return (
        <>
            {
                (detail.detail.length > 0) ?
                    <div className={'relative w-full h-fit ' + (detail.detail.length > 0 ? 'block' : 'hidden')} onMouseEnter={() => setHover(true)} onMouseLeave={() => setHover(false)}>
                        <div className='absolute w-fit h-fit top-0 right-0 w-fit h-fit scale-100 hover:scale-150 transition-transform duration-300 z-20 cursor-pointer'
                        onClick={handle_close}>
                            <box-icon name='x' color='#ffffff' ></box-icon>
                        </div>

                        <div className={'absolute top-0 left-0 w-full h-full bg-black/70 flex justify-center items-center cursor-pointer transition-opacity duration-300 ' + (hover ? 'opacity-100' : 'opacity-0')}
                        onClick={handle_click}>
                            <p className='notosan-reg text-white'>เปลี่ยน หรือ เพิ่มเนื้อหา</p>
                        </div>
        
                        <KM_paragraph details={detail.detail} last={true}/>
                    </div>
                :
                    null
            }
        </>
    )
}

const Author_name = ({info, last, handle_change_author}) => {
    const Author_name_with_modal = ({info, handle_click}) => {
        const [ hover, setHover ] = useState(false);
        const [ position_right, setPosition_right ] = useState(false); //popover is placed on the right, change to bottom when component is place on right of the screen
        const ref = useRef(null);
    
        useEffect(() => {
            const handlePosition = () => {
                if(ref.current) {
                    const rect = ref.current.getBoundingClientRect();
                    const screenWidth = window.innerWidth;
    
                    if(Math.abs(screenWidth - rect.right) < 300) {
                        setPosition_right(false);
                    }
                    else {
                        setPosition_right(true);
                    }
                }
            }
    
            setPosition_right(false);
            handlePosition();
            window.addEventListener('resize', handlePosition);
    
            return () => window.removeEventListener('resize', handlePosition);
        }, []);
    
        return (
            <span className='relative'>
                <span ref={ref} className={'relative inline-block cursor-pointer transition-color duration-300'}
                onClick={handle_click} onMouseEnter={() => setHover(true)} onMouseLeave={() => setHover(false)}>
                    <span className={'absolute top-0 left-0 w-full h-full flex justify-center items-end bg-black/70 ' + (hover ? 'visible' : 'invisible')}>
                        <span className='notosan-light text-white text-sm w-fit h-fit'>เปลี่ยน</span>
                    </span>

                    <span className={'w-fit h-fit notosan-med text-web-gold'}>{info.name}</span>
                </span>
                
                <div className={'absolute translate-y-full w-fit h-fit ' + (hover ? 'z-20 ' : 'z-0 ') + (position_right ? 'bottom-0 left-0' : 'bottom-0 right-0')}>
                    <div className={'w-72 h-fit bg-white border border-gray-200 drop-shadow-md rounded-md px-4 py-5 mt-2 transition duration-300 ' + (hover ? 'opacity-100' : 'opacity-0')}>
                        <div className='w-14 h-14 min-w-14 rounded-full overflow-hidden'>
                            <img src={info.image} className='w-full h-full object-cover'/>
                        </div>
    
                        <p className='notosan-semi text-web-black my-2'>{info.name}</p>
                        <p className='notosan-reg text-web-black/50 text-xs'>{info.position}</p>
                    </div>
                </div>
            </span>
        )
    }

    return (
        <>
            <Author_name_with_modal info={info} handle_click={handle_change_author}/>

            {
                (last ? null : <span className='notosan-med text-web-gold mr-3'>,</span>)
            }
        </>
    )
}

const Modal_add_author = ({isShow, info, edit_info, handle_add_profile, handle_cancel, handle_submit}) => {
    const [ authorID, setAuthorID ] = useState(-1);
    const [ prefix, setPrefix ] = useState('');
    const [ name, setName ] = useState('');
    const [ image, setImage ] = useState('');
    const [ position, setPosition ] = useState('');

    const ref_prefix = useRef(null);
    const ref_name = useRef(null);
    const ref_position = useRef(null);

    useEffect(() => {
        if(!isShow) {
            setAuthorID(-1);
            setPrefix('');
            setName('');
            setImage('');
            setPosition('');
        }
    }, [isShow]);

    useEffect(() => {
        //image was added from outside the modal, once it changed, also update state of this modal
        setImage(info.image ? info.image : '');
    }, [info]);

    useEffect(() => {
        if(Object.keys(edit_info).length > 0) {
            setAuthorID(edit_info.id);
            setPrefix(edit_info.prefix);
            setName(edit_info.name);
            setImage(edit_info.image ? edit_info.image : '');
            setPosition(edit_info.position);
        }
    }, [edit_info]);

    const Button_add_profile = ({handle_click}) => {
        const [ hover, setHover ] = useState(false);

        return (
            <div className={'w-20 h-20 flex justify-center items-center rounded-full border cursor-pointer transition-color duration-200 ' + (hover ? 'bg-zinc-700 border-zinc-700' : 'bg-white border-gray-300')}
            onClick={handle_click} onMouseEnter={() => setHover(true)} onMouseLeave={() => setHover(false)}>
                <box-icon name='plus' color={hover ? '#ffffff' : '#3f3f46'}></box-icon>
            </div>
        )
    }

    const Example_profile = ({handle_click}) => {
        const [ hover, setHover ] = useState(false);

        return (
            <div className='relative w-20 h-20 rounded-full overflow-hidden object-cover border border-gray-300 cursor-pointer'
            onClick={handle_click} onMouseEnter={() => setHover(true)} onMouseLeave={() => setHover(false)}>
                <div className={'absolute w-full h-full flex justify-center items-center bg-black/70 transition-opacity duration-300 ' + (hover ? 'opacity-100' : 'opacity-0')}>
                    <span className='notosan-light text-white text-sm'>เปลี่ยน</span>
                </div>

                <img src={image} className='w-full h-auto'/>
            </div>
        )
    }

    return (
        <div className={isShow ? 'block' : 'hidden'}>
            <div className='absolute top-0 left-0 w-screen h-screen flex justify-center items-center z-30'>
                <div className='w-5/6 lg:w-2/6 h-fit px-6 sm:px-10 py-10 bg-white rounded-md shadow-md'>
                    <div className='w-full h-fit flex justify-center mt-2 mb-6'>
                        {
                            (image.length > 0) ?
                                <Example_profile handle_click={handle_add_profile}/>
                            :
                                <Button_add_profile handle_click={handle_add_profile}/>
                        }
                    </div>

                    <p className='notosan-reg text-web-black'>กรุณาระบุคำนำหน้า/ตำแหน่ง และ ชื่อ-นามสกุล</p>

                    <div className='w-full h-fit grid grid-cols-3 gap-x-2 mt-2 mb-4'>
                        <div className='w-full h-12'>
                            <p className='notosan-reg text-web-black text-xs mb-1.5'>คำนำหน้า/ตำแหน่ง</p>
                            <input className='notosan-reg w-full h-hit border border-gray-300 rounded-sm py-1.5 px-4 focus:outline focus:outline-mahidol-blue focus:outline-2 focus:outline-offset-1'
                            ref={ref_prefix} type='text' placeholder={'เช่น คุณ, รศ., นพ.'} value={prefix}
                            onChange={(e) => setPrefix(e.target.value)}/>
                        </div>

                        <div className='w-full h-12 col-span-2'>
                            <p className='notosan-reg text-web-black text-xs mb-1.5'>ชื่อ-นามสกุล</p>
                            <input className='notosan-reg w-full h-hit border border-gray-300 rounded-sm py-1.5 px-4 focus:outline focus:outline-mahidol-blue focus:outline-2 focus:outline-offset-1'
                            ref={ref_name} type='text' placeholder={'ชื่อ-นามสกุล'} value={name}
                            onChange={(e) => setName(e.target.value)}/>
                        </div>
                    </div>

                    <p className='notosan-reg text-web-black mt-8 mb-2'>กรุณาระบุตำแหน่ง</p>
                    <input className='notosan-reg w-full h-hit border border-gray-300 rounded-sm py-1.5 px-4 focus:outline focus:outline-mahidol-blue focus:outline-2 focus:outline-offset-1'
                    ref={ref_position} type='text' placeholder={'กรุณาระบุตำแหน่ง'} value={position}
                    onChange={(e) => setPosition(e.target.value)}/>

                    <div className={'flex flex-col-reverse sm:grid sm:grid-cols-2 gap-3 w-full h-auto min-h-12 mt-4'}>
                        <button className='notosan-med w-full h-full bg-gray-300 hover:bg-gray-500 py-3 rounded-md' onClick={handle_cancel}>ยกเลิก</button>
                        <button type='button' onClick={() => handle_submit(authorID, prefix, name, image, position)}
                        className={'notosan-med w-full h-full py-3 rounded-md ' + ((prefix.length > 0 && name.length > 0 && position.length > 0) ? 'bg-mahidol-blue hover:bg-mahidol-yellow text-white hover:text-web-black cursor-pointer' : 'bg-gray-300 text-gray-500 cursor-not-allowed')}>ยืนยัน</button>
                    </div>
                </div>
            </div>

            <div className='absolute top-0 left-0 w-screen h-screen bg-black opacity-50 z-20'></div>
        </div>
    )
}

const Modal_list_author = ({isShow, authors, selections, handle_add, handle_delete, handle_edit, handle_close, handle_submit}) => {
    const [ focus, setFocus ] = useState(false);
    const [ db_authors, setDB_authors ] = useState([]);
    const [ searching_word, setSearching_word ] = useState('');
    const [ filter_authors, setFilter_authors ] = useState([]);
    const [ items, setItems ] = useState([]);
    const [ selected_items, setSelected_items ] = useState([]);

    const [ page, setPage ] = useState(1);
    const [ items_per_page, setItems_per_page ] = useState(17);
    const [ max_page, setMax_page ] = useState(1);
    const [ prevent_animation, setPrevent_animation ] = useState(false); //animation is want when page change, but stop animation when page changed
    const [ item_to_delete, setItem_to_delete ] = useState({});

    const [ is_SubmitConfirm, setIsSubmitConfirm ] = useState(false);
    const [ is_SubmitLoading, setIsSubmitLoading ] = useState(false);
    const [ is_SubmitSuccess, setIsSubmitSuccess ] = useState(null);
    const [ modal_submit_process, setModal_submit_process ] = useState("โปรดรอสักครู่");

    const ref_input = useRef(null);

    useEffect(() => {
        if(isShow) {
            let result = [];

            for(let i = 0; i < authors.length; i++) {
                let obj = {...authors[i]};
                obj.selection = false;
                result.push(obj);
            }

            if(selections.length > 0) {
                const selected_id = selections.map(item => parseInt(item.id));

                for(let i = 0; i < result.length; i++) {
                    if(selected_id.indexOf(parseInt(result[i].id)) !== -1) {
                        result[i].selection = true;
                    }
                }
            }

            setSelected_items([...selections]);
            setDB_authors(result);
        }
    }, [isShow, authors]);

    const screen_width = window.innerWidth;

    useEffect(() => {
        filtering();
    }, [page, db_authors, searching_word]);

    useEffect(() => {
        //setItems_per_page((screen_width >= 1024 && screen_width < 1536) ? 9 : 8);

        const index_start = (page * items_per_page) - items_per_page; //Or (page * 8) - 8
        const index_end = (page * items_per_page > db_authors.length) ? db_authors.length : page * items_per_page;
        setItems(filter_authors?.slice(index_start, index_end));
        setMax_page(Math.ceil(filter_authors.length / items_per_page));

        //set which items are selected
        let selections = [];

        for(let i = 0; i < db_authors.length; i++) {
            if(db_authors[i].selection) {
                selections.push(db_authors[i]);
            }
        }

        setSelected_items(selections);

        //add animation into page
        if(!prevent_animation) {
            animation_controls.start("hidden");

            setTimeout(() => {
                animation_controls.start("visible");
            }, 500);
        }
        else {
            setPrevent_animation(false);
        }
    }, [filter_authors]);

    const animation_controls = useAnimationControls();
    const ani_obj = {
        hidden: {
            opacity: 0,
            y: 10,
            transition: { duration: 0.2 }
        },
        visible: (index) => ({
            opacity: 1,
            y: 0,
            transition: { duration: 0.8, delay: index * 0.05 }
        })
    }

    const Add_Button = ({handle_click}) => {
        const [ hover, setHover ] = useState(false);

        return (
            <div className='w-full h-fit flex flex-col items-center'>
                <div className={'w-14 h-14 min-w-14 flex justify-center items-center rounded-full border cursor-pointer transition-color duration-200 ' + (hover ? 'bg-zinc-700 border-zinc-700' : 'bg-white border-gray-300')}
                onClick={handle_click} onMouseEnter={() => setHover(true)} onMouseLeave={() => setHover(false)}>
                    <box-icon name='plus' color={hover ? '#ffffff' : '#3f3f46'}></box-icon>
                </div>

                <p className='notosan-reg text-web-black text-xs line-clamp-2 my-2'>เพิ่มรายชื่อ</p>
            </div>
        )
    }

    const Author_Item = ({info, selected, handle_click, handle_delete, handle_edit}) => {
        const [ hover, setHover ] = useState(false);
        const [ isDelete, setIsDelete ] = useState(false);

        return (
            <div className='w-full h-fit flex flex-col items-center cursor-pointer'
            onMouseEnter={() => setHover(true)} onMouseLeave={() => setHover(false)} onClick={() => handle_click(info.id, isDelete)}>
                {
                    selected ?
                        <div className='relative w-fit h-fit'>
                            <div className='w-14 h-14 min-w-14 rounded-full overflow-hidden'>
                                <img src={info.image} className='w-full h-full object-cover'/>
                            </div>

                            <div className='absolute top-0 right-0 translate-x-1/2 w-6 h-6 bg-web-gold rounded-full'>
                                <box-icon name='check' color='#ffffff' ></box-icon>
                            </div>
                        </div>
                    :
                        <div className='relative w-fit h-fit'>
                            <div className='w-14 h-14 min-w-14 rounded-full overflow-hidden'>
                                <img src={info.image} className='w-full h-full object-cover'/>
                            </div>

                            <div className={'absolute top-0 right-0 translate-x-1/2 -translate-y-1/3 w-fit h-fit flex flex-col gap-1.5'}
                            onMouseEnter={() => setIsDelete(true)} onMouseLeave={() => setIsDelete(false)}>
                                <div className={'w-6 h-6 bg-gray-500 rounded-full transition duration-300 ' + (hover ? 'opacity-100' : 'opacity-0 translate-y-1.5')}
                                onClick={handle_delete}>
                                    <box-icon name='x' color='#ffffff' ></box-icon>
                                </div>
                                
                                <div className={'w-6 h-6 bg-gray-500 rounded-full transition duration-300 delay-100 ' + (hover ? 'opacity-100' : 'opacity-0 translate-y-1.5')}
                                onClick={handle_edit}>
                                    <div className='w-fit h-fit scale-75'>
                                        <box-icon name='edit-alt' type='solid' color='#ffffff' ></box-icon>
                                    </div>
                                </div>
                            </div>
                        </div>
                }

                <p className={'text-xs line-clamp-2 my-2 ' + ((selected || hover) ? 'notosan-semi text-web-gold' : 'notosan-reg text-web-black')}>{info.prefix + info.name}</p>
            </div>
        )
    }

    function filtering() {
        //when filter and select item, it will affact db_authors but not filter_authors
        //so, a list showing to users will not changed when filtering because searching affact db_authors but not filter_authors
        //so, link them before assign them to items
        let result = [];

        for(let i = 0; i < db_authors.length; i++) {
            const prefix = db_authors[i].prefix;
            const name = db_authors[i].name;
            const position = db_authors[i].position;

            if(prefix.includes(searching_word) || name.includes(searching_word) || position.includes(searching_word)) {
                result.push(db_authors[i]);
            }
        }

        setFilter_authors((searching_word.length > 0) ? [...result] : [...db_authors]);
    }

    function reset_modal() {
        setFocus(false);
        setDB_authors([]);
        setSearching_word('');
        setFilter_authors([]);

        setPage(1);
        setItems_per_page(17);
        setMax_page(1);
        setItems([]);
        setSelected_items([]);
        setPrevent_animation(false);
        setItem_to_delete({});

        if(ref_input.current) {
            ref_input.current.value = '';
        }
    }

    function delete_author() {
        if(Object.keys(item_to_delete).length > 0) {
            axios.post(php_list.php_km_update_author_to_delete, JSON.stringify({
                id: item_to_delete.id,
            }))
            .then(response => {
                // Handle the success response
                console.log(response.data);
    
                if (!response.data.success) {
                    setIsSubmitLoading(false);
                    setIsSubmitSuccess('failed');
                }
            })
            .catch(errors => {
                // Handle the error
                console.error('Error:', errors);
    
                setIsSubmitLoading(false);
                setIsSubmitSuccess('failed');
            })
            .finally(() => {
                setIsSubmitLoading(false);
                setIsSubmitSuccess('success');
                handle_delete();
            });
        }
    }

    return (
        <>
            <div className={isShow ? 'block' : 'hidden'}>
                <div className='absolute top-0 left-0 w-screen h-screen flex justify-center items-center z-30'>
                    <div className='w-5/6 lg:w-2/6 h-fit px-6 sm:px-10 py-10 bg-white rounded-md shadow-md'>
                        <p className='notosan-reg text-web-black'>โปรดเลือกรายชื่ออาจารย์ (เลือกได้มากกว่า 1 รายชื่อ)</p>

                        <div className={'w-full h-fit flex gap-x-5 mt-4 mb-8'}>
                            {
                                (selected_items.length === 0) ?
                                    <p className='w-full h-fit notosan-reg text-black/50 text-center text-sm'>ยังไม่มีรายชื่อที่เลือก</p>
                                :
                                    selected_items.map((x, i) => {
                                        return (
                                            <div key={i} className='relative w-fit h-fit'>
                                                <div className='w-14 h-14 min-w-14 rounded-full overflow-hidden'>
                                                    <img src={x.image} className='w-full h-full object-cover'/>
                                                </div>

                                                <div className='absolute top-0 right-0 translate-x-1/2 w-6 h-6 bg-web-gold rounded-full'>
                                                    <box-icon name='check' color='#ffffff' ></box-icon>
                                                </div>
                                            </div>
                                        )
                                    })
                            }
                        </div>

                        <div className='relative w-full h-fit my-4'>
                            <input ref={ref_input} className='w-full h-fit border border-gray-300 px-4 py-1.5 rounded-md focus:outline focus:outline-mahidol-blue focus:outline-2 focus:outline-offset-1'
                            onFocus={() => setFocus(true)} onBlur={(e) => setFocus(e.target.value.length > 0)}
                            onChange={(e) => setSearching_word(e.target.value)}></input>

                            <div className={'absolute top-1/2 -translate-y-1/2 left-2 w-fit h-fit flex gap-x-2 transition-opacity duration-100 ' + (focus ? 'opacity-0' : 'opacity-100')}>
                                <UilSearch size="20" color="#A9A9A9"/>
                                <p className='notosan-reg text-web-black/50' onClick={() => {
                                    if(ref_input.current) {
                                        setFocus(true);
                                        ref_input.current.focus();
                                    }
                                }}>ค้นหารายชื่อ...</p>
                            </div>
                        </div>

                        <div className='w-full h-fit grid grid-cols-6 gap-5 mt-10 mb-4'>
                            <Add_Button handle_click={handle_add}/>

                            {
                                items.map((x, i) => {
                                    return (
                                        <motion.div
                                        key={i}
                                        variants={ani_obj}
                                        initial="hidden"
                                        animate={animation_controls}
                                        custom={i}>
                                            <Author_Item info={x} selected={x.selection}
                                            handle_click={(id, isDelete) => {
                                                if(!isDelete) {
                                                    setPrevent_animation(true);

                                                    if(db_authors.length > 0) {
                                                        const prev_db = [...db_authors];
                                                        const index = prev_db.findIndex(x => x.id === id);
                                                        prev_db[index].selection = !prev_db[index].selection;
                                                        setDB_authors(prev_db);
                                                    }
                                                }
                                            }}
                                            handle_delete={() => {
                                                setItem_to_delete(x);
                                                setIsSubmitConfirm(true);
                                            }}
                                            handle_edit={() => handle_edit(x)}/>
                                        </motion.div>
                                    )
                                })
                            }
                        </div>

                        <div className='w-full h-fit flex justify-center scale-75'>
                            <Pagination current_page={page} max_page={max_page} 
                            handle_change_page={(x) => setPage(x)}/>
                        </div>

                        <div className={'grid grid-cols-1 sm:grid-cols-2 gap-3 w-full h-auto min-h-12 mt-8'}>
                            <button className='notosan-med w-full h-full bg-gray-300 hover:bg-gray-500 py-3 rounded-md' 
                            onClick={() => {
                                handle_close();
                                reset_modal();
                            }}>ยกเลิก</button>

                            <button className={'notosan-med w-full h-full py-3 rounded-md ' + (selected_items.length > 0 ? 'bg-mahidol-blue hover:bg-mahidol-yellow text-white hover:text-web-black cursor-pointer' : 'bg-gray-300 text-gray-500 cursor-not-allowed')} 
                            onClick={() => {
                                if(selected_items.length > 0) {
                                    handle_submit(selected_items);
                                    reset_modal();
                                }
                            }}>ยืนยัน</button>
                        </div>
                    </div>
                </div>

                <div className='absolute top-0 left-0 w-screen h-screen bg-black opacity-50 z-20'></div>
            </div>

            <ModalConfirmForm 
            isShow={is_SubmitConfirm} 
            isLoading={is_SubmitLoading} 
            isSuccess={is_SubmitSuccess} 
            process={modal_submit_process} 
            onConfirm={() => delete_author()} 
            onCancel={() => {
                setIsSubmitConfirm(false);
                setIsSubmitLoading(false);
                setIsSubmitSuccess(null);
            }}
            label={"ยืนยันการลบข้อมูลใช่หรือไม่?"}
            label_success={{
                title: 'ลบข้อมูลสำเร็จ',
                subtitle: null,
            }}
            label_failed={{
                title: 'ล้มเหลว พบปัญหาในการส่งข้อมูล',
                subtitle: null,
            }}/>
        </>
    )
}

const Modal_list_author_head = ({isShow, authors, selection, handle_add, handle_delete, handle_edit, handle_close, handle_submit}) => {
    const [ focus, setFocus ] = useState(false);
    const [ db_authors, setDB_authors ] = useState([]);
    const [ searching_word, setSearching_word ] = useState('');
    const [ filter_authors, setFilter_authors ] = useState([]);
    const [ items, setItems ] = useState([]);
    const [ selected_items, setSelected_items ] = useState({});

    const [ page, setPage ] = useState(1);
    const [ items_per_page, setItems_per_page ] = useState(17);
    const [ max_page, setMax_page ] = useState(1);
    const [ prevent_animation, setPrevent_animation ] = useState(false); //animation is want when page change, but stop animation when page changed
    const [ item_to_delete, setItem_to_delete ] = useState({});
    const [ active_submit, setActive_submit ] = useState(false);

    const [ is_SubmitConfirm, setIsSubmitConfirm ] = useState(false);
    const [ is_SubmitLoading, setIsSubmitLoading ] = useState(false);
    const [ is_SubmitSuccess, setIsSubmitSuccess ] = useState(null);
    const [ modal_submit_process, setModal_submit_process ] = useState("โปรดรอสักครู่");

    const ref_input = useRef(null);

    useEffect(() => {
        if(isShow) {
            let result = [];

            for(let i = 0; i < authors.length; i++) {
                let obj = {...authors[i]};
                obj.selection = false;
                result.push(obj);
            }

            if(selection.length > 0) {
                const selected_name = selection[0].name;
                const index = result.findIndex(x => x.name === selected_name);
                result[index].selection = true;
            }

            setDB_authors(result);
        }
    }, [isShow, authors]);

    const screen_width = window.innerWidth;

    useEffect(() => {
        filtering();
    }, [page, db_authors, searching_word]);

    useEffect(() => {
        //setItems_per_page((screen_width >= 1024 && screen_width < 1536) ? 9 : 8);

        const index_start = (page * items_per_page) - items_per_page; //Or (page * 8) - 8
        const index_end = (page * items_per_page > db_authors.length) ? db_authors.length : page * items_per_page;
        setItems(filter_authors?.slice(index_start, index_end));
        setMax_page(Math.ceil(filter_authors.length / items_per_page));

        //add animation into page
        if(!prevent_animation) {
            animation_controls.start("hidden");

            setTimeout(() => {
                animation_controls.start("visible");
            }, 500);
        }
        else {
            setPrevent_animation(false);
        }
    }, [filter_authors]);

    const animation_controls = useAnimationControls();
    const ani_obj = {
        hidden: {
            opacity: 0,
            y: 10,
            transition: { duration: 0.2 }
        },
        visible: (index) => ({
            opacity: 1,
            y: 0,
            transition: { duration: 0.8, delay: index * 0.05 }
        })
    }

    const Add_Button = ({handle_click}) => {
        const [ hover, setHover ] = useState(false);

        return (
            <div className='w-full h-fit flex flex-col items-center'>
                <div className={'w-14 h-14 min-w-14 flex justify-center items-center rounded-full border cursor-pointer transition-color duration-200 ' + (hover ? 'bg-zinc-700 border-zinc-700' : 'bg-white border-gray-300')}
                onClick={handle_click} onMouseEnter={() => setHover(true)} onMouseLeave={() => setHover(false)}>
                    <box-icon name='plus' color={hover ? '#ffffff' : '#3f3f46'}></box-icon>
                </div>

                <p className='notosan-reg text-web-black text-xs line-clamp-2 my-2'>เพิ่มรายชื่อ</p>
            </div>
        )
    }

    const Author_Item = ({info, selected, handle_click, handle_delete, handle_edit}) => {
        const [ hover, setHover ] = useState(false);
        const [ isDelete, setIsDelete ] = useState(false);

        return (
            <div className='w-full h-fit flex flex-col items-center cursor-pointer'
            onMouseEnter={() => setHover(true)} onMouseLeave={() => setHover(false)} onClick={() => handle_click(info.id, isDelete)}>
                {
                    selected ?
                        <div className='relative w-fit h-fit'>
                            <div className='w-14 h-14 min-w-14 rounded-full overflow-hidden'>
                                <img src={info.image} className='w-full h-full object-cover'/>
                            </div>

                            <div className='absolute top-0 right-0 translate-x-1/2 w-6 h-6 bg-web-gold rounded-full'>
                                <box-icon name='check' color='#ffffff' ></box-icon>
                            </div>
                        </div>
                    :
                        <div className='relative w-fit h-fit'>
                            <div className='w-14 h-14 min-w-14 rounded-full overflow-hidden'>
                                <img src={info.image} className='w-full h-full object-cover'/>
                            </div>

                            <div className={'absolute top-0 right-0 translate-x-1/2 -translate-y-1/3 w-fit h-fit flex flex-col gap-1.5'}
                            onMouseEnter={() => setIsDelete(true)} onMouseLeave={() => setIsDelete(false)}>
                                <div className={'w-6 h-6 bg-gray-500 rounded-full transition duration-300 ' + (hover ? 'opacity-100' : 'opacity-0 translate-y-1.5')}
                                onClick={handle_delete}>
                                    <box-icon name='x' color='#ffffff' ></box-icon>
                                </div>
                                
                                <div className={'w-6 h-6 bg-gray-500 rounded-full transition duration-300 delay-100 ' + (hover ? 'opacity-100' : 'opacity-0 translate-y-1.5')}
                                onClick={handle_edit}>
                                    <div className='w-fit h-fit scale-75'>
                                        <box-icon name='edit-alt' type='solid' color='#ffffff' ></box-icon>
                                    </div>
                                </div>
                            </div>
                        </div>
                }

                <p className={'text-xs line-clamp-2 my-2 ' + ((selected || hover) ? 'notosan-semi text-web-gold' : 'notosan-reg text-web-black')}>{info.prefix + info.name}</p>
            </div>
        )
    }

    function filtering() {
        //when filter and select item, it will affact db_authors but not filter_authors
        //so, a list showing to users will not changed when filtering because searching affact db_authors but not filter_authors
        //so, link them before assign them to items
        let result = [];

        for(let i = 0; i < db_authors.length; i++) {
            const prefix = db_authors[i].prefix;
            const name = db_authors[i].name;
            const position = db_authors[i].position;

            if(prefix.includes(searching_word) || name.includes(searching_word) || position.includes(searching_word)) {
                result.push(db_authors[i]);
            }
        }

        setFilter_authors((searching_word.length > 0) ? [...result] : [...db_authors]);
    }

    function reset_modal() {
        setFocus(false);
        setDB_authors([]);
        setSearching_word('');
        setFilter_authors([]);

        setPage(1);
        setItems_per_page(17);
        setMax_page(1);
        setItems([]);
        setPrevent_animation(false);
        setItem_to_delete({});

        if(ref_input.current) {
            ref_input.current.value = '';
        }
    }

    function delete_author() {
        if(Object.keys(item_to_delete).length > 0) {
            axios.post(php_list.php_km_update_author_to_delete, JSON.stringify({
                id: item_to_delete.id,
            }))
            .then(response => {
                // Handle the success response
                console.log(response.data);
    
                if (!response.data.success) {
                    setIsSubmitLoading(false);
                    setIsSubmitSuccess('failed');
                }
            })
            .catch(errors => {
                // Handle the error
                console.error('Error:', errors);
    
                setIsSubmitLoading(false);
                setIsSubmitSuccess('failed');
            })
            .finally(() => {
                setIsSubmitLoading(false);
                setIsSubmitSuccess('success');
                handle_delete();
            });
        }
    }

    return (
        <>
            <div className={isShow ? 'block' : 'hidden'}>
                <div className='absolute top-0 left-0 w-screen h-screen flex justify-center items-center z-30'>
                    <div className='w-5/6 lg:w-2/6 h-fit px-6 sm:px-10 py-10 bg-white rounded-md shadow-md'>
                        <p className='notosan-reg text-web-black'>โปรดเลือกรายชื่ออาจารย์ (เลือกได้เพียง 1 รายชื่อ)</p>

                        <div className='relative w-full h-fit my-4'>
                            <input ref={ref_input} className='w-full h-fit border border-gray-300 px-4 py-1.5 rounded-md focus:outline focus:outline-mahidol-blue focus:outline-2 focus:outline-offset-1'
                            onFocus={() => setFocus(true)} onBlur={(e) => setFocus(e.target.value.length > 0)}
                            onChange={(e) => setSearching_word(e.target.value)}></input>

                            <div className={'absolute top-1/2 -translate-y-1/2 left-2 w-fit h-fit flex gap-x-2 transition-opacity duration-100 ' + (focus ? 'opacity-0' : 'opacity-100')}>
                                <UilSearch size="20" color="#A9A9A9"/>
                                <p className='notosan-reg text-web-black/50' onClick={() => {
                                    if(ref_input.current) {
                                        setFocus(true);
                                        ref_input.current.focus();
                                    }
                                }}>ค้นหารายชื่อ...</p>
                            </div>
                        </div>

                        <div className='w-full h-fit grid grid-cols-6 gap-5 mt-10 mb-4'>
                            <Add_Button handle_click={handle_add}/>

                            {
                                items.map((x, i) => {
                                    return (
                                        <motion.div
                                        key={i}
                                        variants={ani_obj}
                                        initial="hidden"
                                        animate={animation_controls}
                                        custom={i}>
                                            <Author_Item info={x} selected={x.selection}
                                            handle_click={(id, isDelete) => {
                                                if(!isDelete) {
                                                    setSelected_items({});
                                                    setPrevent_animation(true);

                                                    if(db_authors.length > 0) {
                                                        setActive_submit(false);
                                                        const prev_db = [...db_authors];
                                                        let current_id = -1;

                                                        for(let j = 0; j < prev_db.length; j++) {
                                                            if(prev_db[j].selection) {
                                                                current_id = prev_db[j].id;
                                                                prev_db[j].selection = false;
                                                                break;
                                                            }
                                                        }

                                                        if(items[i].id !== current_id) {
                                                            const selected_index = prev_db.findIndex(x => x.id === id);
                                                            prev_db[selected_index].selection = true;
                                                            setSelected_items({...prev_db[selected_index]});
                                                            setActive_submit(true);
                                                        }

                                                        setDB_authors(prev_db);
                                                    }
                                                }
                                            }}
                                            handle_delete={() => {
                                                setItem_to_delete(x);
                                                setIsSubmitConfirm(true);
                                            }}
                                            handle_edit={() => {
                                                handle_edit(x);
                                            }}/>
                                        </motion.div>
                                    )
                                })
                            }
                        </div>

                        <div className='w-full h-fit flex justify-center scale-75'>
                            <Pagination current_page={page} max_page={max_page} 
                            handle_change_page={(x) => setPage(x)}/>
                        </div>

                        <div className={'grid grid-cols-1 sm:grid-cols-2 gap-3 w-full h-auto min-h-12 mt-8'}>
                            <button className='notosan-med w-full h-full bg-gray-300 hover:bg-gray-500 py-3 rounded-md' 
                            onClick={() => {
                                handle_close();
                                reset_modal();
                            }}>ยกเลิก</button>

                            <button className={'notosan-med w-full h-full py-3 rounded-md ' + (active_submit ? 'bg-mahidol-blue hover:bg-mahidol-yellow text-white hover:text-web-black cursor-pointer' : 'bg-gray-300 text-gray-500 cursor-not-allowed')} 
                            onClick={() => {
                                handle_submit(selected_items);
                                reset_modal();
                            }}>ยืนยัน</button>
                        </div>
                    </div>
                </div>

                <div className='absolute top-0 left-0 w-screen h-screen bg-black opacity-50 z-20'></div>
            </div>

            <ModalConfirmForm 
            isShow={is_SubmitConfirm} 
            isLoading={is_SubmitLoading} 
            isSuccess={is_SubmitSuccess} 
            process={modal_submit_process} 
            onConfirm={() => delete_author()} 
            onCancel={() => {
                setIsSubmitConfirm(false);
                setIsSubmitLoading(false);
                setIsSubmitSuccess(null);
            }}
            label={"ยืนยันการลบข้อมูลใช่หรือไม่?"}
            label_success={{
                title: 'ลบข้อมูลสำเร็จ',
                subtitle: null,
            }}
            label_failed={{
                title: 'ล้มเหลว พบปัญหาในการส่งข้อมูล',
                subtitle: null,
            }}/>
        </>
    )
}

const Admin_KM_add = () => {
    const [ image_cover, setImageCover ] = useState(null);
    const [ date, setDate ] = useState(null);
    const [ title, setTitle ] = useState('');
    const [ author_head, setAuthor_head ] = useState([]); //there is only 1 name to be head, but using array to check condition if head is empty or not, to decide if some button appeared
    const [ authors, setAuthors ] = useState([]);
    const [ details, setDetails ] = useState([]);
    const [ language, setLanguage ] = useState(-1);
    const [ db_authors, setDB_authors ] = useState([]);

    const [ info_for_edit_author, setInfo_for_edit_author ] = useState({});

    const [ modal_add_img, setModal_add_img ] = useState(false);
    const [ modal_add_text, setModal_add_text ] = useState(false);
    const [ modal_add_date, setModal_add_date ] = useState(false);
    const [ modal_list_author, setModal_list_author ] = useState(false);
    const [ modal_list_author_head, setModal_list_author_head ] = useState(false);
    const [ modal_add_author, setModal_add_author ] = useState(false);
    const [ modal_add_author_img, setModal_add_author_img ] = useState(false);
    const [ modal_add_detail_title, setModal_add_detail_title ] = useState(false);
    const [ modal_add_detail_detail, setModal_add_detail_detail ] = useState(false);
    const [ update_detail_title, setUpdate_detail_title ] = useState(null);
    const [ update_detail_details, setUpdate_detail_details ] = useState([]);
    const [ update_detail_index, setUpdate_detail_index ] = useState(-1);
    const [ author_info_obj, setAuthor_info_obj ] = useState(
        {
            prefix: null,
            name: null,
            image: null,
            position: null,
        }
    );

    const [ res_title, setRes_title ] = useState({});
    const [ res_author, setRes_author ] = useState({});
    const [ res_detail, setRes_detail ] = useState({});

    const { authorised, id } = useParams();
    const ref_language = useRef(null);
    const navigate = useNavigate();

    useEffect(() => {
        if(authorised !== 'authorised') {
            navigate('/admin');
        }

        if(id !== null && id!== undefined) {
            let abortcontroller = new AbortController();

            const fetchData = async () => {
                try {
                    const requests = [
                        axios.get(php_list.php_km_read_title_id, { 
                            params: { id: id },
                            signal: abortcontroller.signal 
                        }),
                        axios.get(php_list.php_km_read_author_id, { 
                            params: { id: id },
                            signal: abortcontroller.signal 
                        }),
                        axios.get(php_list.php_km_read_detail_id, { 
                            params: { id: id },
                            signal: abortcontroller.signal 
                        }),
                    ];
                    
                    const index_title = 0;
                    const index_author = 1;
                    const index_detail = 2;
                    const responses = await Promise.all(requests);

                    setRes_title(responses[index_title]);
                    setRes_author(responses[index_author]);
                    setRes_detail(responses[index_detail]);
                }
                catch (error) {
                    if (axios.isCancel(error)) {
                        console.log('Request canceled:', error.message);
                    } else {
                        console.error('Error fetching data:', error);
                    }
                }
            };

            fetchData();

            return () => abortcontroller.abort();
        }
    }, []);

    useEffect(() => {
        let abortcontroller = new AbortController();

        if(db_authors.length === 0) {
            axios.get(php_list.php_km_read_author, { signal: abortcontroller.signal })
            .then((response) => {
                // Handle the success response
                console.log(response.data);

                if (response.data.success) {
                    setDB_authors([...response.data.results]);
                } 
                else {
                    console.log('Error loading data: ' + response.data.message);
                }
            })
            .catch((error) => {
                console.log(error);
            });
        }

        return () => abortcontroller.abort();
    }, [db_authors]);

    useEffect(() => {
        if(update_detail_index !== -1) {
            const topic_for_modal = details[update_detail_index].topic;
            const details_for_modal = [...details[update_detail_index].detail];

            setUpdate_detail_title(topic_for_modal);
            setUpdate_detail_details(details_for_modal);
        }
    }, [update_detail_index]);

    //When loading for update
    useEffect(() => {
        if(Object.keys(res_title).length > 0) {
            setTitle(res_title.data.title);
            setDate(res_title.data.date);
            setImageCover(res_title.data.cover);
            setLanguage(res_title.data.language);

            if(ref_language.current) {
                const options = Array.from(ref_language.current.options).map(x => parseInt(x.value));
                const value = parseInt(res_title.data.language);
                ref_language.current.selectedIndex = options.indexOf(value);
            }
        }
    }, [res_title]);

    useEffect(() => {
        if(Object.keys(res_author).length > 0) {
            let result_head = [];
            let result_author = [];
            const db_authors = [...res_author.data.results];

            for(let i = 0; i < db_authors.length; i++) {
                const head = (parseInt(db_authors[i]["head"]) === 1);
                
                if(head) {
                    result_head.push({
                        'id': db_authors[i].km_id,
                        'prefix': db_authors[i].prefix,
                        'name': db_authors[i].name,
                        'image': db_authors[i].image,
                        'position': db_authors[i].position,
                    })
                }
                else {
                    result_author.push({
                        'id': db_authors[i].km_id,
                        'prefix': db_authors[i].prefix,
                        'name': db_authors[i].name,
                        'image': db_authors[i].image,
                        'position': db_authors[i].position,
                    });
                }
            }

            setAuthor_head(result_head);
            setAuthors(result_author);
        }
    }, [res_author]);

    useEffect(() => {
        if(Object.keys(res_detail).length > 0) {
            load_detail_from_res_detail();
        }
    }, [res_detail]);

    const [ is_SubmitConfirm, setIsSubmitConfirm ] = useState(false);
    const [ is_SubmitLoading, setIsSubmitLoading ] = useState(false);
    const [ is_SubmitSuccess, setIsSubmitSuccess ] = useState(null);
    const [ modal_submit_process, setModal_submit_process ] = useState("โปรดรอสักครู่");

    const load_detail_from_res_detail = async() => {
        const topic_without_details = await set_topics();
        const topic_with_details = await fulfill_details(topic_without_details);
        await set_details(topic_with_details);

        function set_details(prev_arr) {
            return new Promise((resolve) => {
                if(prev_arr.length > 0) {
                    setDetails([...prev_arr]);
                }

                resolve();
            })
        }

        function fulfill_details(prev_arr) {
            return new Promise((resolve) => {
                if(Object.keys(res_detail).length > 0 && prev_arr.length > 0) {
                    const db_details = [...res_detail.data.results];
                    let result = [...prev_arr];

                    //set topic
                    for(let i = 0; i < result.length; i++) {
                        const topic = result[i].topic;
                        let detail = [];

                        for(let j = 0; j < db_details.length; j++) {
                            if(topic === db_details[j].topic) {
                                detail.push({
                                    paragraph: parseInt(db_details[j].paragraph),
                                    type: db_details[j].type,
                                    indent: (parseInt(db_details[j].indent) === 1),
                                    image: db_details[j].image,
                                    detail: db_details[j].detail,
                                });
                            }
                        }

                        detail.sort((a, b) => a.paragraph - b.paragraph);
                        result[i].detail = [...detail];
                    }

                    resolve(result);
                }
            })
        }

        function set_topics() {
            return new Promise((resolve) => {
                if(Object.keys(res_detail).length > 0) {
                    const db_details = [...res_detail.data.results];
                    const max_topicIndex = Math.max(...db_details.map(x => parseInt(x.topicIndex)));
        
                    let result = [];
        
                    //set topic
                    for(let i = 0; i <= max_topicIndex; i++) {
                        for(let j = 0; j < db_details.length; j++) {
                            const index = parseInt(db_details[j].topicIndex);
        
                            if(i === index) {
                                const obj = {
                                    topic: db_details[j].topic,
                                    detail: [],
                                }
        
                                result.push(obj);
                                break;
                            }
                        }
                    }

                    resolve(result);
                }
            })
        }
    }

    function recheck_completeness_before_submit() {
        if(image_cover === null || image_cover === undefined || image_cover === '') {
            alert("ยังไม่ได้เพิ่มรูปของบทความ โปรดเพิ่มก่อนกดยืนยัน");
            return;
        }

        if(title === null || title === undefined || title === '') {
            alert("ยังไม่ได้เพิ่มหัวข้อบทความ โปรดเพิ่มก่อนกดยืนยัน");
            return;
        }

        if(date === null || date === undefined || date === '') {
            alert("ยังไม่ได้ระบุวันที่โพสต์บทความ โปรดระบุก่อนกดยืนยัน");
            return;
        }

        if(author_head.length === 0) {
            alert("ยังไม่ได้เพิ่มรายชื่อผู้ที่เกี่ยวข้อง โปรดเพิ่มก่อนกดยืนยัน");
            return;
        }

        if(details.length === 0) {
            alert("ยังไม่ได้เพิ่มเนื้อหาของบทความ โปรดเพิ่มก่อนกดยืนยัน");
            return;
        }

        if(language === -1) {
            alert('ยังไม่ได้เพิ่มภาษา โปรดเพิ่มก่อนกดยืนยัน');
            return;
        }
        
        setIsSubmitConfirm(true);
    }

    async function submit_data() {
        if(id === null || id === undefined) {
            const latestId = await send_main_data();
            await send_authors(latestId);

            /*
            console.log('insert');
            console.log(details);
            await send_details(29);
            */

            /*
            [
                {
                    "topic": "ปัจจัยในความสำเร็จ",
                    "detail": [
                        {
                            "paragraph": 0,
                            "type": "text",
                            "indent": false,
                            "image": null,
                            "detail": "1. ต้องหาพันธมิตรมาช่วยกันทำงานที่หลากหลาย มีความเชี่ยวชาญเฉพาะด้านมาร่วมกันทำงานโดยใช้หลักสามเหลี่ยมเขยื้อนภูเขาของอาจารย์ประเวศ วะสี"
                        },
                        {
                            "paragraph": 1,
                            "type": "text",
                            "indent": false,
                            "image": null,
                            "detail": "2. ทำให้ทุกคนมีเป้าหมายเดียวกัน และเกาะติดเรื่องเดียวไปตลอด วิเคราะห์ให้ได้ว่าปัจจัยหลักที่มีผลต่อเรื่องของเราคืออะไร แล้วกำหนดแผนงานที่ชัดเจนทั้งระยะสั้นและระยะยาว"
                        },
                        {
                            "paragraph": 2,
                            "type": "text",
                            "indent": false,
                            "image": null,
                            "detail": "3. จัดหาทุนมาสนับสนุนทีมงาน และพันธมิตรทั้งงานวิชาการ และการรณรงค์ประชาสัมพันธ์อย่างต่อเนื่อง"
                        },
                        {
                            "paragraph": 3,
                            "type": "text",
                            "indent": false,
                            "image": null,
                            "detail": "4. อย่าได้ท้อถอย วันรุ่งขึ้นอาจเป็นวันของเรา เก็บชัยชนะเล็กๆ เป็นกำลังใจให้เราทำต่อไป ทำไปต่อเนื่องอย่างไม่มีที่สิ้นสุด และให้ทำด้วยวิธีที่หลากหลาย ไม่มีงานไหนที่ทำด้วยวิธีเดียวแล้วได้ผล"
                        }
                    ]
                },
                {
                    "topic": "ความท้าทาย",
                    "detail": [
                        {
                            "paragraph": 0,
                            "type": "text",
                            "indent": false,
                            "image": null,
                            "detail": "1. การทำงานร่วมกับพันธมิตรบางอย่างไม่ได้อยู่ในการควบคุมของเรา จำเป็นต้องใช้ระยะเวลา ถ้าติดตรงไหนก็ไปทำส่วนอื่นก่อน"
                        },
                        {
                            "paragraph": 1,
                            "type": "text",
                            "indent": false,
                            "image": null,
                            "detail": "2. ไม่ว่าโครงการไหน กว่าจะทำสำเร็จก็ต้องใช้เวลาทั้งสิ้น ถ้าลงมือทำต่อเนื่อง 5 ปี คนจะเริ่มเข้าใจในสิ่งที่เราตั้งใจทำ พอเข้า 10 ปี ถึงจะเริ่มคล่องตัวขึ้น และต้องทำต่อไปเรื่อยๆ ให้เหมาะสมกับบริบทของสังคมก็เปลี่ยนแปลงตามสมัย"
                        }
                    ]
                },
                {
                    "topic": "แรงผลักดันที่ทำให้ประชาชนและสังคม",
                    "detail": [
                        {
                            "paragraph": 0,
                            "type": "text",
                            "indent": true,
                            "image": null,
                            "detail": "ยึดถือคำสอนของพระบิดา \"ขอให้ถือผลประโยชน์ส่วนตัวเป็นที่สอง ประโยชน์ของเพื่อนมนุษย์เป็นกิจที่หนึ่ง ลาภ ทรัพย์และเกียรติยศ จะตกมาแก่ท่านเอง ถ้าท่านทรงธรรมแห่งอาชีพไว้ให้บริสุทธิ์"
                        }
                    ]
                }
            ]
            */
            
            await send_details(latestId);
        }
        else {
            //update
            await update_main_data();
            await send_authors(id);
            
            /*
            console.log('update');
            console.log(details);
            await send_details(29);
            */

            await send_details(id);
        }

        function update_main_data() {
            setIsSubmitLoading(true);

            let obj_date = new Date(date);
            let export_date = obj_date.getFullYear() + "-" + (obj_date.getMonth() + 1) + "-" + obj_date.getDate();

            return new Promise((resolve, reject) => {
                axios.post(php_list.php_km_update, JSON.stringify({
                    id: id,
                    cover: image_cover,
                    title: title,
                    date: export_date,
                    available: 1,
                    language: language,
                }))
                .then(response => {
                    // Handle the success response
                    console.log(response.data);
                })
                .catch(errors => {
                    // Handle the error
                    console.error('Error:', errors);
        
                    setIsSubmitLoading(false);
                    setIsSubmitSuccess('failed');
                })
                .finally(() => {
                    resolve();
                });
            });
        }

        function send_main_data() {
            setIsSubmitLoading(true);

            let obj_date = new Date(date);
            let export_date = obj_date.getFullYear() + "-" + (obj_date.getMonth() + 1) + "-" + obj_date.getDate();
            let latestId = -1;

            return new Promise((resolve, reject) => {
                axios.post(php_list.php_km_write, JSON.stringify({
                    cover: image_cover,
                    title: title,
                    date: export_date,
                    available: 1,
                    language: language,
                }))
                .then(response => {
                    // Handle the success response
                    console.log(response.data);
        
                    if (response.data.success) {
                        latestId = response.data.latestId;
        
                        //setIsSubmitLoading(false);
                        //setIsSubmitSuccess('success');
                    } else {
                        setIsSubmitLoading(false);
                        setIsSubmitSuccess('failed');
                    }
                })
                .catch(errors => {
                    // Handle the error
                    console.error('Error:', errors);
        
                    setIsSubmitLoading(false);
                    setIsSubmitSuccess('failed');
                })
                .finally(() => {
                    resolve(latestId);
                });
            });
        }

        //send for insert and also update authors
        function send_authors(latestId) {
            return new Promise((resolve, reject) => {
                if(latestId === -1) {
                    resolve();
                    return;
                }

                for(let i = 0; i < author_head.length; i++) {
                    const author_id = author_head[i].id;

                    axios.post(php_list.php_km_write_author_link, JSON.stringify({
                        latestId: parseInt(latestId),
                        author_id: parseInt(author_id),
                        head: true,
                    }))
                    .then(response => {
                        // Handle the success response
                        console.log(response.data);
                    })
                    .catch(errors => {
                        // Handle the error
                        console.error('Error:', errors);
                    })
                    .finally(() => {
                        resolve();
                    });
                }

                /*
                for(let i = 0; i < authors.length; i++) {
                    const author_id = authors[i].id;

                    axios.post(php_list.php_km_write_author_link, JSON.stringify({
                        latestId: parseInt(latestId),
                        author_id: parseInt(author_id),
                        head: false,
                    }))
                    .then(response => {
                        // Handle the success response
                        console.log(response.data);
                    })
                    .catch(errors => {
                        // Handle the error
                        console.error('Error:', errors);
                    })
                    .finally(() => {
                        resolve();
                    });
                }
                */
            });
        }

        //send for insert and also update details
        function send_details(latestId) {
            return new Promise((resolve, reject) => {
                if(latestId === -1) {
                    resolve();
                    return;
                }

                for(let i = 0; i < details.length; i++) {
                    const topic = details[i].topic;
                    //const detail = details[i].detail[0];
                    const detail = details[i].detail;

                    for(let j = 0; j < detail.length; j++) {
                        console.log(i);
                        console.log({
                            latestId: latestId,
                            topic: topic,
                            topicIndex: i,
                            paragraph: j,
                            type: detail[j].type,
                            indent: detail[j].indent,
                            image: detail[j].image,
                            detail: detail[j].detail,
                        });

                        axios.post(php_list.php_km_write_detail, JSON.stringify({
                            latestId: latestId,
                            topic: topic,
                            topicIndex: i,
                            paragraph: j,
                            type: detail[j].type,
                            indent: detail[j].indent,
                            image: detail[j].image,
                            detail: detail[j].detail,
                        }))
                        .then(response => {
                            // Handle the success response
                            console.log(response.data);
                
                            if (response.data.success) {
                                //setIsSubmitLoading(false);
                                //setIsSubmitSuccess('success');
                            } else {
                                setIsSubmitLoading(false);
                                setIsSubmitSuccess('failed');
                            }
                        })
                        .catch(errors => {
                            // Handle the error
                            console.error('Error:', errors);
                
                            setIsSubmitLoading(false);
                            setIsSubmitSuccess('failed');
                        })
                        .finally(() => {
                            console.log((i === details.length - 1 && j === detail.length - 1));

                            if(i === details.length - 1 && j === detail.length - 1) {
                                setIsSubmitLoading(false);
                                setIsSubmitSuccess('success');
                                resolve();
                            }
                        });
                    }

                    resolve();
                }
            });
        }
    }

    return (
        <div className='relative w-screen h-screen z-0'>
            { /* Content of Home */ }
            <div className='w-screen h-screen overflow-y-scroll'>
                <Header/>

                <div className='relative w-full h-60'>
                    <div className='absolute bottom-0 left-0 w-full h-4/6 bg-gradient-to-t from-black opacity-80 z-10'></div>
                    <img src={cover} className='relative w-full h-full object-cover'/>
                </div>

                <div className='mx-10 sm:mx-20 xl:mx-48 py-28'>
                    <div className='w-full h-fit flex justify-end mb-4'>
                        <select ref={ref_language} className='w-fit h-fit border border-gray-500 px-4 py-2 rounded-full cursor-pointer' onChange={(e) => setLanguage(e.target.value)}>
                            <option value={-1}>โปรดเลือกภาษา...</option>
                            <option value={0}>ภาษาไทย</option>
                            <option value={1}>ภาษาอังกฤษ</option>
                        </select>
                    </div>

                    <div>
                        {
                            (image_cover) ?
                                <Example_image url={image_cover} handle_change={() => setModal_add_img(true)}/>
                            :
                                <div className='w-full h-96'>
                                    <Admin_add_button label={'เพิ่มรูปของบทความ'} handle_click={() => setModal_add_img(true)}/>
                                </div>
                        }
                    </div>

                    <div>
                        {
                            (title) ? 
                                <div className='relative w-full h-fit mt-16'>
                                    <Example_title title={title} handle_change={() => setModal_add_text(true)}/>
                                </div>
                            :
                                <div className='w-full h-14 mt-16'>
                                    <Admin_add_button label={'เพิ่มหัวข้อบทความ'} handle_click={() => setModal_add_text(true)}/>
                                </div>
                        }
                    </div>

                    <div className='w-fit h-fit flex my-4'>
                        <div className={'w-40 min-w-40 h-12 mr-4 ' + (date ? 'hidden' : 'block')}>
                            <Admin_add_button label={'เพิ่มวันที่'} handle_click={() => setModal_add_date(true)}/>
                        </div>

                        <div className='w-full h-fit min-h-12'>
                            {
                                (date) ? <Example_date date={date} handle_change={() => setModal_add_date(true)}/> : null
                            }
                            
                            <span className='notosan-med text-web-gold mx-4'>|</span>

                            {
                                (author_head.length === 0) ?
                                    null
                                :
                                    <Author_name info={author_head[0]} last={!authors.length > 0} handle_change_author={() => {
                                        setModal_list_author_head(true);
                                    }}/>
                            }

                            {
                                (authors.length === 0) ?
                                    null
                                :
                                    authors.map((x, i) => {
                                        return (
                                            <Author_name key={i} info={x} last={i === authors.length - 1} handle_change_author={() => {
                                                setModal_list_author(true);
                                            }}/>
                                        )
                                    })
                            }
                        </div>

                        <div className={'w-40 min-w-40 h-12 ml-4 ' + (author_head.length > 0 ? 'hidden' : 'block')}>
                            <Admin_add_button label={'เพิ่มรายชื่อหลัก'} handle_click={() => setModal_list_author_head(true)}/>
                        </div>

                        <div className={'w-40 min-w-40 h-12 ml-4 ' + (authors.length > 0 ? 'hidden' : 'block')}>
                            <Admin_add_button label={'เพิ่มรายชื่อรอง'} handle_click={() => setModal_list_author(true)}/>
                        </div>
                    </div>

                    <div className={'w-full h-12 mt-2 mb-4 ' + (authors.length > 0 ? 'block' : 'hidden')}>
                        <Admin_add_button label={'เพิ่มรายชื่อรอง'} handle_click={() => setModal_list_author(true)}/>
                    </div>

                    <div className='w-32 h-1.5 bg-web-gold my-8'></div>

                    {
                        details.map((x, i) => {
                            return (
                                <div key={i} className='w-full h-fit'>
                                    <Example_detail_title detail={x}
                                    handle_click={() => {
                                        setUpdate_detail_index(i);
                                        setModal_add_detail_title(true);
                                    }}
                                    handle_close={() => {
                                        let result = [...details];
                                        result.splice(i, 1);
                                        setDetails(result);
                                    }}/>

                                    <Example_detail_detail detail={x}
                                    handle_click={() => {
                                        //add detail in modal
                                        setUpdate_detail_index(i);

                                        setUpdate_detail_title(details[i].topic);
                                        setUpdate_detail_details(details[i].detail);
                                        
                                        setModal_add_detail_detail(true);
                                    }}
                                    handle_close={() => {
                                        let result = [...details];
                                        result[i].detail = [];
                                        setDetails(result);
                                    }}/>

                                    {
                                        <div className={'w-full h-12 my-4 ' + ((x.detail.length === 0) ? 'block' : 'hidden')}>
                                            <Admin_add_button label={'เพิ่มเนื้อหาในหัวข้อนี้ใหม่'} handle_click={() => {
                                                setUpdate_detail_index(i);
                                                setModal_add_detail_detail(true);
                                            }}/>
                                        </div>
                                    }

                                    <div className='w-full h-fit flex justify-center items-center'>
                                        <hr className={'w-3/4 ' + (i === details.length - 1 ? 'my-12' : 'mt-12')}/>
                                    </div>

                                    <div className={'w-full h-12 my-4 ' + (i === details.length - 1 ? 'block' : 'hidden')}>
                                        <Admin_add_button label={'เพิ่มหัวข้อใหม่'} 
                                        handle_click={() => setModal_add_detail_title(true)}/>
                                    </div>
                                </div>
                            )
                        })
                    }

                    <div className={'w-full h-12 my-4 ' + (details.length > 0 ? 'hidden' : 'block')}>
                        <Admin_add_button label={'เพิ่มหัวข้อใหม่'} 
                        handle_click={() => {
                            setModal_add_detail_title(true);
                        }}/>
                    </div>

                    <div className='w-full h-fit flex justify-center gap-5 mt-20'>
                        <RoundFullButton text={'กลับหน้าเดิม'} onclick={() => navigate('/admin/authorised')}/>
                        <RoundFullButton text={'ส่งข้อมูล'} onclick={() => recheck_completeness_before_submit()}/>
                    </div>
                </div>

                <Footer form={false}/>
            </div>

            { /* Any modal */ }
            <Modal_add_image isShow={modal_add_img} handle_cancel={() => setModal_add_img(false)}
            handle_submit={(result) => {
                setImageCover(result);
                setModal_add_img(false);
            }}/>

            <Modal_add_text isShow={modal_add_text} label={'กรุณาระบุหัวข้อของข่าวที่ต้องการเพิ่ม'}
            handle_cancel={() => setModal_add_text(false)} 
            handle_submit={(result) => {
                setTitle(result);
                setModal_add_text(false);
            }}/>

            <Modal_add_date isShow={modal_add_date} handle_cancel={() => setModal_add_date(false)} 
            handle_submit={(result) => {
                setDate(result);
                setModal_add_date(false);
            }}/>

            <Modal_list_author 
            isShow={modal_list_author} 
            authors={db_authors}
            selections={authors}
            handle_add={() => setModal_add_author(true)}
            handle_delete={() => {
                setDB_authors([]);
            }}
            handle_edit={(info) => {
                setInfo_for_edit_author({...info});
                setModal_add_author(true);
            }}
            handle_close={() => setModal_list_author(false)}
            handle_submit={(lists) => {
                let result = [];

                for(let i = 0; i < lists.length; i++) {
                    const obj = {
                        'id': lists[i].id,
                        'prefix': lists[i].prefix,
                        'name': lists[i].name,
                        'image': lists[i].image,
                        'position': lists[i].position,
                    }

                    result.push(obj);
                }

                setAuthors(result);
                setModal_list_author(false);
            }}/>

            <Modal_list_author_head
            isShow={modal_list_author_head}
            authors={db_authors}
            selection={author_head}
            handle_add={() => setModal_add_author(true)}
            handle_delete={() => {
                setDB_authors([]);
            }}
            handle_edit={(info) => {
                setInfo_for_edit_author({...info});
                setModal_add_author(true);
            }}
            handle_close={() => setModal_list_author_head(false)}
            handle_submit={(info) => {
                let result = [{
                    'id': info.id,
                    'prefix': info.prefix,
                    'name': info.name,
                    'image': info.image,
                    'position': info.position,
                }];

                setAuthor_head(result);
                setModal_list_author_head(false);
            }}/>

            <Modal_add_author 
            isShow={modal_add_author}
            info={author_info_obj}
            edit_info={info_for_edit_author}
            handle_add_profile={() => {
                setModal_add_author_img(true);
            }}
            handle_cancel={() => setModal_add_author(false)}
            handle_submit={(authorID, prefix, name, image, position) => {
                if(authorID === -1) {
                    //INSERT NEW DATA
                    axios.post(php_list.php_km_write_author, JSON.stringify({
                        prefix: prefix,
                        name: name,
                        image: image,
                        position: position,
                    }))
                    .then(response => {
                        // Handle the success response
                        console.log(response.data);
                    })
                    .catch(errors => {
                        // Handle the error
                        console.error('Error:', errors);
                    });
    
                    setModal_add_author(false);
                }
                else {
                    //UPDATE DATA
                    axios.post(php_list.php_km_update_author, JSON.stringify({
                        id: authorID,
                        prefix: prefix,
                        name: name,
                        image: image,
                        position: position,
                    }))
                    .then(response => {
                        // Handle the success response
                        console.log(response.data);
                    })
                    .catch(errors => {
                        // Handle the error
                        console.error('Error:', errors);
                    })
                    .finally(() => {
                        setModal_add_author(false);
                        setDB_authors([]);
                    });
                }
            }}/>

            <Modal_add_image isShow={modal_add_author_img} handle_cancel={() => setModal_add_author_img(false)}
            handle_submit={(result) => {
                let obj = {...author_info_obj};
                obj.image = result;

                setAuthor_info_obj(obj);
                setModal_add_author_img(false);
            }}/>

            <Modal_add_text isShow={modal_add_detail_title} label={'กรุณาระบุหัวข้อใหม่'}
            handle_cancel={() => setModal_add_detail_title(false)} 
            handle_submit={(result) => {
                let prev = [...details];

                if(update_detail_index === -1) {
                    let obj = {
                        topic: result,
                        detail: [],
                    };

                    prev.push(obj);
                }
                else {
                    prev[update_detail_index]["topic"] = result;
                }

                setDetails(prev);
                setUpdate_detail_index(-1);
                setModal_add_detail_title(false);
            }}/>

            <Modal_Admin_KM_add_detail 
            isShow={modal_add_detail_detail}
            title={update_detail_title}
            details={update_detail_details}
            handle_cancel={() => {
                setUpdate_detail_title(null);
                setUpdate_detail_details([]);
                setUpdate_detail_index(-1);
                setModal_add_detail_detail(false);
            }}
            handle_submit={(modal_title, modal_details) => {
                let result = [...details];

                result[update_detail_index] = {
                    topic: modal_title,
                    detail: modal_details,
                }

                setDetails(result);
                setUpdate_detail_title(null);
                setUpdate_detail_details([]);
                setUpdate_detail_index(-1);
                setModal_add_detail_detail(false);
            }}/>

            <ModalConfirmForm 
            isShow={is_SubmitConfirm} 
            isLoading={is_SubmitLoading} 
            isSuccess={is_SubmitSuccess} 
            process={modal_submit_process} 
            onConfirm={submit_data} 
            onCancel={() => {
                setIsSubmitConfirm(false);
                setIsSubmitLoading(false);
                setIsSubmitSuccess(null);
            }}
            label={"ยืนยันการส่งข้อมูลใช่หรือไม่?"}
            label_success={{
                title: 'ส่งข้อมูลสำเร็จ',
                subtitle: null,
            }}
            label_failed={{
                title: 'ล้มเหลว พบปัญหาในการส่งข้อมูล',
                subtitle: null,
            }}/>
        </div>
    )
}

export default Admin_KM_add