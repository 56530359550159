import React, { useEffect, useState } from 'react';
import { Link } from 'react-router-dom';
import Quote from '../Components/Other/Quote';
import title_decorate from '../../assets/decorate/title_decorate.svg';

const KM_paragraph = ({details, last}) => {
    const Text_with_separate_highlight = ({texts, indent}) => {
        return (
            <p className={'my-4 ' + (indent ? 'indent-16' : 'indent-0')}>
                {
                    texts.map((x, i) => {
                        return (
                            <span key={i} className='w-fit h-fit'>
                                <Text_with_separate_link text={x} index={i}/>
                            </span>
                        )
                    })
                }
            </p>
        )
    }

    const Text_with_separate_link = ({text, index}) => {
        const texts = text.split("<LINK>");

        return (
            <>
                {
                    texts.map((x, i) => {
                        return (
                            (i%2 === 1) ?
                                <span key={i}><Link_text str={x}/></span>
                            :
                                <span key={i} className={'notosan-reg ' + (index%2 === 1 ? 'text-web-gold' : 'text-web-black')}>{x}</span>
                        )
                    })
                }
            </>
        )
    }

    const Link_text = ({str}) => {
        const [ obj_link, setObj_link ] = useState({});

        useEffect(() => {
            const texts = str.split("<URL>"); //this return 3 length array [ '', url, text ]
            
            setObj_link({
                url: texts[1],
                text: texts[2],
            })
        }, [str]);

        return (
            <Link to={obj_link.url} target="_blank" rel="noopener noreferrer" className={'notosan-reg text-web-gold hover:underline'}>{obj_link.text}</Link>
        )
    }

    return (
        <div className='w-full h-fit'>
            {
                details.map((x, i) => {
                    const texts_highlight = x.detail?.split("<HL>");

                    return (
                        <div key={i} className='w-full h-fit'>
                            {
                                texts_highlight ? 
                                    <div key={i} className='w-full h-fit'>
                                        {
                                            (x.type === 'text') ?
                                                <Text_with_separate_highlight texts={texts_highlight} indent={x.indent}/>
                                            :
                                                null
                                        }
                                    </div>
                                :
                                    null
                            }

                            {
                                (x.type === 'image') ?
                                    <div className='w-4/6 h-fit mx-auto overflow-visible mt-14 mb-4'>
                                        <img src={x.image} className='w-full h-auto object-cover rounded-sm' style={{maxHeight: '500px'}}/>
                                        
                                        {
                                            (x.detail) ? 
                                                <p className='w-full notosan-reg text-web-black/70 text-sm text-center mt-4'>{x.detail}</p>
                                            :
                                                null
                                        }
                                    </div>
                                :
                                    null
                            }

                            {
                                (x.type === 'quote') ?
                                    <div className='w-4/6 h-fit mx-auto mt-20'>
                                        <img src={title_decorate} className='w-28 h-auto mx-auto mt-4 mb-8'/>
                                        <Quote quote={x.detail}/>
                                    </div>
                                :
                                    null
                            }
                        </div>
                    )
                })
            }

            {
                (last) ?
                    null
                :
                    <div className='w-full h-fit flex justify-center items-center'>
                        <hr className='w-3/4 mt-12'/>
                    </div>
            }
        </div>
    )
}

export default KM_paragraph