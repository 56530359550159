import React, { useEffect, useRef, useState } from 'react';
import title_decorate from '../../assets/decorate/title_decorate.svg';
import RoundFullButton from '../Components/Button/RoundFullButton';
import { Swiper, SwiperSlide } from 'swiper/react';
import { Pagination, Autoplay } from 'swiper/modules';
import 'swiper/css';
import 'swiper/css/pagination';

import { php_list } from '../../php/php_list';
import NewsCard from '../Components/Card/NewsCard';
import { Link, useNavigate } from 'react-router-dom';
import axios from 'axios';
import Swiper_Navigation_Roundbutton from '../Components/Swiper/Swiper_Navigation_Roundbutton';

const HomeNews = () => {
    const [ page, setPage ] = useState(1);
    const [ db, setDB ] = useState([]);
    const [ items, setItems ] = useState([]);

    const ref_swiper = useRef(null);
    
    const navigate = useNavigate();

    useEffect(() => {
        let abortcontroller = new AbortController();

        axios.get(php_list.php_news_read_all, { signal: abortcontroller.signal })
        .then((response) => {
            // Handle the success response
            console.log(response.data);

            if (response.data.success) {
                setDB([...response.data.results]);
            } 
            else {
                console.log('Error loading data: ' + response.data.message);
            }
        })
        .catch((error) => {
            console.log(error);
        });

        return () => abortcontroller.abort();
    }, []);

    useEffect(() => {
        const items_per_page = 8;
        const index_start = (page * items_per_page) - items_per_page; //Or (page * 8) - 8
        const index_end = (page * items_per_page > db.length) ? db.length : page * items_per_page;

        setItems(db?.slice(index_start, index_end));
    }, [page, db]);

    function handle_see_all() {
        navigate('/news');
    }

    return (
        <div className='w-full h-fit py-10'>
            <div className='w-full h-fit flex flex-col justify-center my-6'>
                <h3 className='text-web-gold text-3xl sm:text-4xl notosan-med text-center'>ข่าวสารองค์กร</h3>
                <img src={title_decorate} className='w-28 h-auto self-center my-4'/>
            </div>

            {
                (items.length === 0) ?
                    <div className='mx-10 sm:mx-20 md:mx-28 h-20'>
                        { /* Loading Spinder */ }
                        <div className={'grid grid-cols-1 gap-10 w-full h-auto min-h-12 mt-8'}>
                            <div className='flex justify-center items-center w-full h-full bg-pink-bold rounded-md cursor-not-allowed'>
                                <div role="status">
                                    <svg aria-hidden="true" className="w-6 h-6 text-gray-300 animate-spin dark:text-gray-600 fill-web-gold" viewBox="0 0 100 101" fill="none" xmlns="http://www.w3.org/2000/svg">
                                        <path d="M100 50.5908C100 78.2051 77.6142 100.591 50 100.591C22.3858 100.591 0 78.2051 0 50.5908C0 22.9766 22.3858 0.59082 50 0.59082C77.6142 0.59082 100 22.9766 100 50.5908ZM9.08144 50.5908C9.08144 73.1895 27.4013 91.5094 50 91.5094C72.5987 91.5094 90.9186 73.1895 90.9186 50.5908C90.9186 27.9921 72.5987 9.67226 50 9.67226C27.4013 9.67226 9.08144 27.9921 9.08144 50.5908Z" fill="currentColor"/>
                                        <path d="M93.9676 39.0409C96.393 38.4038 97.8624 35.9116 97.0079 33.5539C95.2932 28.8227 92.871 24.3692 89.8167 20.348C85.8452 15.1192 80.8826 10.7238 75.2124 7.41289C69.5422 4.10194 63.2754 1.94025 56.7698 1.05124C51.7666 0.367541 46.6976 0.446843 41.7345 1.27873C39.2613 1.69328 37.813 4.19778 38.4501 6.62326C39.0873 9.04874 41.5694 10.4717 44.0505 10.1071C47.8511 9.54855 51.7191 9.52689 55.5402 10.0491C60.8642 10.7766 65.9928 12.5457 70.6331 15.2552C75.2735 17.9648 79.3347 21.5619 82.5849 25.841C84.9175 28.9121 86.7997 32.2913 88.1811 35.8758C89.083 38.2158 91.5421 39.6781 93.9676 39.0409Z" fill="currentFill"/>
                                    </svg>
                                </div>
                            </div>
                        </div>
                    </div>
                :
                    <div className='relative mx-10 sm:mx-20 md:mx-28'>
                        <div className='absolute top-1/2 left-0 w-fit h-fit -ml-6 z-10 hover:-translate-x-1/4 transition duration-300'>
                            <Swiper_Navigation_Roundbutton boxicon_name={'chevron-left'} handle_onclick={() => {
                                const currentIndex = parseInt(ref_swiper.current.swiper.activeIndex);
                                const index = ((currentIndex - 1) < 0) ? 0 : currentIndex - 1;
                                ref_swiper?.current?.swiper?.slideToLoop(index);
                            }}/>
                        </div>

                        <Swiper
                            ref={ref_swiper}
                            spaceBetween={0}
                            slidesPerView={1}
                            breakpoints={{
                                // when window width is >= 700px
                                700: {
                                    spaceBetween: 10,
                                    slidesPerView: 2,
                                },
                                // when window width is >= 1000px
                                1000: {
                                    spaceBetween: 10,
                                    slidesPerView: 3,
                                },
                                // when window width is >= 1300px
                                1300: {
                                    spaceBetween: 20,
                                    slidesPerView: 4,
                                },
                                // when window width is >= 1600px
                                1600: {
                                    spaceBetween: 50,
                                    slidesPerView: 4,
                                },
                            }}
                            pagination={{ clickable: true, dynamicBullets: true, }}
                            modules={[Pagination, Autoplay]}
                            autoplay={{
                                delay: 5000,
                                disableOnInteraction: false,
                                pauseOnMouseEnter: true,
                            }}
                            loop={true}>
                            {
                                items.map((x, i) => {
                                    return (
                                        <SwiperSlide key={i}>
                                            <Link to={'/news/detail/' + x.id} target="_blank" rel="noopener noreferrer">
                                                <NewsCard title={x.title} image={x.cover} date={x.date} detail={x.detail}/>
                                            </Link>
                                        </SwiperSlide>
                                    )
                                })
                            }
                        </Swiper>

                        <div className='absolute top-1/2 right-0 w-fit h-fit -mr-6 z-10 hover:translate-x-1/4 transition duration-300'>
                            <Swiper_Navigation_Roundbutton boxicon_name={'chevron-right'} handle_onclick={() => {
                                const currentIndex = ref_swiper.current.swiper.activeIndex;
                                const num_slides = parseInt(ref_swiper.current.swiper.slides.length);
                                const index = ((currentIndex + 1) > (num_slides - 1)) ? 0 : (currentIndex + 1);
                                ref_swiper?.current?.swiper?.slideToLoop(index);
                            }}/>
                        </div>
                    </div>
            }

            <div className='w-full h-fit flex justify-center mt-4'>
                <RoundFullButton text={'ดูทั้งหมด'} onclick={handle_see_all}/>
            </div>
        </div>
    )
}

export default HomeNews