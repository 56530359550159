import React, { useEffect, useRef, useState } from 'react';
import title_decorate from '../../../assets/decorate/title_decorate.svg';
import { Swiper, SwiperSlide } from 'swiper/react';
import { Pagination, Autoplay } from 'swiper/modules';
import 'swiper/css';
import 'swiper/css/pagination';
import { motion, useAnimationControls } from "framer-motion";

import consulting from '../../../assets/about/consulting.png';
import management from '../../../assets/about/management.png';
import policylab from '../../../assets/about/policylab.png';
import systemlab from '../../../assets/about/systemlab.png';
import transition from '../../../assets/about/transition.png';
import CrossFadeImage from '../../Components/Image/CrossFadeImage';
import Swiper_Navigation_Transparent from '../../Components/Swiper/Swiper_Navigation_Transparent';

const HomeAbout = () => {
    const [ index, setIndex ] = useState(0);
    const [ imageTitle, setImageTitle ] = useState("Health Policy Consulting");
    const [ duties, setDuties ] = useState(
        [
            {
                title: "Health Policy Consulting",
                image: consulting,
                detail: "ศูนย์นโยบายและการจัดการสุขภาพจัดบริการให้คำปรึกษากับคณาจารย์ในการนำองค์ความรู้จากผลงานวิจัย (Explicit Knowledge) หรือความรู้ที่เกิดจากประสบการณ์ (Tacit Knowledge) ไปใช้ในการขยายผลครอบคลุมตั้งแต่ในระดับชุมชน ท้องถิ่น จังหวัด และระดับชาติ ผ่านรูปแบบการให้บริการ Initial Consulting Unit โดยทีมผู้เชี่ยวชาญ เพื่อให้ได้ผลลัพธ์ที่คาดหวัง อาทิเช่น แนวคิด แนวปฏิบัติ หัวข้องานวิจัยหรือโครงการที่เหมาะกับการขยายผล การแปลองค์ความรู้เผยแพร่สู่สังคม และเครือข่ายบุคคลหรือหน่วยงานที่เหมาะสมเพื่อสร้างผลกระทบเชิงบวกและยั่งยืนในด้านสุขภาพของสังคมและประเทศ",
            },
            {
                title: "Project Management",
                image: management,
                detail: "ศูนย์นโยบายและการจัดการสุขภาพจัดบริการช่วยบริหารจัดการงานวิจัยหรือโครงการที่มีแนวโน้มในการนำไปใช้ขยายผล (Implementation) หรือต่อยอดงานไปสู่กระบวนการทำงานเชิงนโยบาย (Policy Process) ร่วมกับหน่วยงานที่มีอำนาจในการตัดสินใจเชิงนโยบายระดับประเทศ บริการของเราครอบคลุมอย่างครบวงจร ตั้งแต่การประสานความร่วมมือกับผู้กำหนดนโยบาย การช่วยเขียนเสนอโครงการขอแหล่งทุน การบริหารจัดการเอกสารหลักฐานทางการเงินต่างๆ ตลอดจนการสนับสนุนการจัดกิจกรรมตามแผนงานวิจัยหรือโครงการที่ได้รับมอบหมาย โดยใช้ทุนจากโครงการขับเคลื่อนนโยบายชี้นำสังคมของมหาวิทยาลัย เพื่อให้ได้ผลลัพธ์ที่คาดหวัง คือ ความสะดวกในการทำงานวิจัยหรือโครงการของคณาจารย์เป็นสำคัญ",
            },
            {
                title: "Health Policy Laboratory",
                image: policylab,
                detail: "ศูนย์นโยบายและการจัดการสุขภาพส่งเสริมการพัฒนานโยบายจากผลงานวิจัยที่มีประเด็นเชื่อมโยงซับซ้อนในสังคม ให้มีโอกาสได้รับการกำหนดเป็นนโยบายสุขภาพ ผ่านการวิเคราะห์และสังเคราะห์ข้อมูลที่ได้จากการจัดเวทีประชุมหารือร่วมกับทุกฝ่าย เพื่อพิจารณาวิธีการผลักดันที่เหมาะสมและรอบด้าน เพื่อให้ได้ผลลัพธ์ที่คาดหวัง คือ โอกาสในการส่งมอบผลงานให้กับหน่วยงานระดับนโยบายในหลายภาคส่วน เพื่อร่วมกันช่วยเหลือประชาชนและสังคมอย่างยั่งยืน",
            },
            {
                title: "Health System Design Laboratory",
                image: systemlab,
                detail: "ศูนย์นโยบายและการจัดการสุขภาพส่งเสริมให้หน่วยงานของคณะฯ พัฒนารูปแบบการให้บริการสุขภาพที่มีประสิทธิภาพและคุ้มค่า ผ่านกระบวนการสร้างการมีส่วนร่วมจากทุกฝ่ายที่เกี่ยวข้อง โดยใช้การวิเคราะห์ สังเคราะห์ และทดลองปฏิบัติ เพื่อให้ได้ผลลัพธ์ที่คาดหวัง คือ ต้นแบบของบริการสุขภาพแนวใหม่ (New care delivery model) หรือรูปแบบที่เหมาะสมตามความต้องการของหน่วยงาน เพื่อเสริมสร้างความสามารถในการให้บริการสุขภาพที่ตอบโจทย์และมีประสิทธิผล",
            },
            {
                title: "Knowledge Translation and Transition",
                image: transition,
                detail: "ศูนย์นโยบายและการจัดการสุขภาพมุ่งหวังให้เกิดการผลักดันองค์ความรู้ทั้งจากผลงานวิจัย (Explicit Knowledge) และความรู้ที่เกิดจากประสบการณ์ (Tacit Knowledge) ไปสู่การกำหนดเป็นนโยบายระดับชาติและนานาชาติ โดยใช้กระบวนการจัดการความรู้เป็นเครื่องมือหลักในการสร้างระบบคัดเลือกและวิธีในการผลักดันผลงานที่พร้อมนำไปใช้ ตลอดจนสนับสนุนให้คณาจารย์มีบทบาทในการเสนอประเด็นทางวิชาการ อันนำไปสู่การสร้างความร่วมมือกับทุกภาคส่วนในการกำหนดมาตรการหรือนโยบายในการแก้ไขปัญหาสุขภาพบนเวทีระดับชาติและนานาชาติ ผลลัพธ์ที่คาดหวัง คือ โอกาสในการส่งมอบผลงานเกิดเป็นการทำงานร่วมกับหน่วยงานในระดับชาติ ส่งผลกระทบที่ดีต่อสุขภาพของประชาชนในวงกว้างอย่างเป็นรูปธรรม",
            },
        ]
    );

    const swiperRef = useRef(null);
    const [ pause_swiper, setPause_swiper ] = useState(false);

    useEffect(() => {
        detail_controls.start('hidden');

        setTimeout(() => {
            setImageTitle(duties[index].title);
            detail_controls.start('visible');
        }, 1000);
    }, [index]);

    useEffect(() => {
        if(pause_swiper) {
            swiperRef?.current?.swiper?.autoplay.pause();
        }
        else {
            swiperRef?.current?.swiper?.autoplay.resume();
        }
    }, [pause_swiper]);

    const detail_controls = useAnimationControls();
    const animationVariants_detail = {
        hidden: {
            opacity: 0,
            x: "5px",
            transition: {duration: 1}
        },
        visible: (index) => ({
            opacity: 100,
            x: 0,
            transition: {duration: 1, delay: index * 0.5}
        })
    }

    function handle_slide_swiper_to(index) {
        //normal slideTo() can be applied with normal Swiper
        //slideToLoop() is used for Swiper with loop property
        swiperRef?.current?.swiper?.slideToLoop(index);
        setIndex(index);
    }

    return (
        <div className='w-full h-fit pt-14 xl:pt-28'>
            <div className='w-full h-fit flex flex-col justify-center my-6'>
                <h3 className='text-web-gold text-3xl sm:text-4xl notosan-med text-center'>เกี่ยวกับเรา</h3>
                <img src={title_decorate} className='w-28 h-auto self-center my-4'/>
            </div>

            <div className='mx-10 sm:mx-20 xl:mx-48'>
                <p className='notosan-reg text-web-black indent-0 sm:indent-20 text-center sm:text-start'>
                    ศูนย์นโยบายและการจัดการสุขภาพ เป็นหน่วยงานภายใต้สังกัดของคณะแพทยศาสตร์โรงพยาบาลรามาธิบดี จัดตั้งขึ้นเพื่อสร้างระบบสนับสนุนให้คณาจารย์สามารถนำความรู้ที่ได้จากงานวิจัย (Explicit Knowledge) และความรู้ที่เกิดจากประสบการณ์ (Tacit Knowledge) ไปใช้ประโยชน์ในรูปแบบการทำงานวิจัยหรือโครงการที่พร้อมขยายผล (Implementation) หรือต่อยอดงานไปสู่กระบวนการทำงานเชิงนโยบาย (Policy Process) ร่วมกับหน่วยงานที่มีอำนาจในการตัดสินใจเชิงนโยบายระดับประเทศ ผลักดันให้เกิดการเปลี่ยนแปลงนโยบายทั้งการกำหนดให้เกิดเป็นนโยบายใหม่ (Upstream policy) และการปรับปรุงนโยบายที่มีอยู่แล้ว (Downstream policy) ให้มีประสิทธิภาพยิ่งขึ้น ในหัวข้อที่คณะฯ หรือสังคมให้ความสำคัญ ส่งผลให้เกิดผลกระทบ (Impact) ที่ดีต่อสุขภาพของประชาชนในวงกว้าง
                </p>

                <p className='notosan-reg text-web-black indent-0 sm:indent-20 text-center sm:text-start mt-3'>
                    ศูนย์นโยบายและการจัดการสุขภาพประกอบไปด้วยหน้าที่ทั้งหมด {duties.length} ด้าน ได้แก่ด้านที่ 1 {duties[0].title} ด้านที่ 2 {duties[1].title} ด้านที่ 3 {duties[2].title} ด้านที่ 4 {duties[3].title} และด้านที่ 5 {duties[4].title}
                </p>
            </div>

            <div className='mx-10 sm:mx-20 xl:mx-48'>
                <div className='relative w-full h-44 rounded-md my-12'>
                    <div className='absolute right-0 bottom-0 flex gap-2 items-center w-52 sm:w-96 h-20 bg-white z-40'>
                        <div className='w-fit h-fit ml-2'>
                            <Swiper_Navigation_Transparent boxicon_name={'chevron-left'} handle_onclick={() => handle_slide_swiper_to((index - 1 < 0) ? duties.length - 1 : index - 1)}/>
                        </div>

                        { /* mx-6 mt-3 h-full */ }
                        <Swiper
                            className='mt-6'
                            ref={swiperRef}
                            spaceBetween={30}
                            slidesPerView={2}
                            breakpoints={{
                                // when window width is >= 768px
                                768: {
                                    spaceBetween: 50,
                                    slidesPerView: 3,
                                },
                            }}
                            modules={[Pagination, Autoplay]}
                            autoplay={{
                                delay: 10000,
                                disableOnInteraction: false,
                                pauseOnMouseEnter: true,
                            }}
                            loop={true}
                            onSlideChange={(swiper) => setIndex(swiper.realIndex)}>
                            {
                                duties.map((x, i) => {
                                    return (
                                        <SwiperSlide key={i} onClick={() => handle_slide_swiper_to(i)}>
                                            {
                                                ({isActive}) => (
                                                    <div className='cursor-pointer'>
                                                        <p className={'notosan-med text-xs ' + (isActive ? 'text-web-gold' : 'text-web-black-secondary')}>0{i + 1}</p>
                                                        <p className={'notosan-light text-xs line-clamp-3 ' + (isActive ? 'text-web-gold' : 'text-web-black-secondary')}>{x.title}</p>
                                                    </div>
                                                )
                                            }
                                        </SwiperSlide>
                                    )
                                })
                            }
                        </Swiper>

                        <div className='w-fit h-fit mr-2 cursor-pointer'>
                            <Swiper_Navigation_Transparent boxicon_name={'chevron-right'} handle_onclick={() => handle_slide_swiper_to((index + 1 > duties.length - 1) ? 0 : index + 1)}/>
                        </div>
                    </div>

                    <motion.div 
                    className='absolute top-0 left-0 hidden lg:flex lg:items-center w-full lg:w-96 h-full ml-6 md:ml-20 z-30'
                    variants={animationVariants_detail}
                    initial="hidden"
                    animate={detail_controls}
                    custom={0}>
                        <p className='notosan-bold w-full text-3xl text-white cursor-default'>{imageTitle}</p>
                    </motion.div>
                    <div className='absolute bottom-0 left-0 w-full h-full bg-gradient-to-t from-black/70 rounded-md z-20'></div>

                    <CrossFadeImage image1={duties[(index - 1 < 0) ? duties.length - 1 : index].image} image2={duties[index].image}/>
                </div>

                <div className='w-fit h-fit cursor-default' onMouseEnter={() => setPause_swiper(true)} onMouseLeave={() => setPause_swiper(false)}>
                    <motion.div className='w-fit h-fit notosan-reg bg-web-black text-white py-1 px-4'
                    variants={animationVariants_detail}
                    initial="hidden"
                    animate={detail_controls}
                    custom={0}>ด้านที่ {index + 1}</motion.div>
                </div>

                <div className='w-fit h-fit' onMouseEnter={() => setPause_swiper(true)} onMouseLeave={() => setPause_swiper(false)}>
                    <motion.div className='w-full h-fit my-4 flex items-center gap-5'
                    variants={animationVariants_detail}
                    initial="hidden"
                    animate={detail_controls}
                    custom={1}>
                        <p className='notosan-semi w-full sm:w-fit h-fit text-web-gold text-2xl'>{duties[index].title}</p>
                        <div className='hidden sm:block h-1 w-24 bg-web-gold'></div>
                    </motion.div>
                </div>

                <div className='w-fit h-fit' onMouseEnter={() => setPause_swiper(true)} onMouseLeave={() => setPause_swiper(false)}>
                    <motion.div className='w-full h-fit min-h-44'
                    variants={animationVariants_detail}
                    initial="hidden"
                    animate={detail_controls}
                    custom={2}>
                        <p className='text-web-black notosan-reg mt-8 indent-0 sm:indent-20'>{duties[index].detail}</p>
                    </motion.div>
                </div>
            </div>
        </div>
    )
}

export default HomeAbout