import React, { useEffect, useState } from 'react';
import { Link, useParams } from 'react-router-dom';
import { activity_list } from '../Admin/Admin_activities/activity_list';
import Header from '../Components/Header/Header';
import Footer from '../Components/Footer/Footer';
import cover from '../../assets/cover/cover_activity.png';
import title_decorate from '../../assets/decorate/title_decorate.svg';
import axios from 'axios';
import { php_list } from '../../php/php_list';

const Activities_detail = () => {
    const {id} = useParams();
    
    const [ image_cover, setImageCover ] = useState(null);
    const [ date, setDate ] = useState(null);
    const [ title, setTitle ] = useState('');

    useEffect(() => {
        axios.get(php_list.php_activity_read_id, {
            params: {
                id: id
            }
        })
        .then((response) => {
            // Handle the success response
            console.log(response.data);

            if (response.data.success) {
                setImageCover(response.data.cover);
                setTitle(response.data.title);
                set_date_formatted(response.data.dateStart, response.data.dateEnd);
            } 
            else {
                alert('Error loading data: ' + response.data.message);
            }
        })
        .catch((error) => {
            console.log(error);
            alert('Error loading data: ' + error);
        });
    }, []);

    function set_date_formatted(dateStart, dateEnd) {
        const thaiMonths = ["มกราคม","กุมภาพันธ์","มีนาคม","เมษายน","พฤษภาคม","มิถุนายน","กรกฎาคม","สิงหาคม","กันยายน","ตุลาคม","พฤศจิกายน","ธันวาคม"];

        if(dateEnd === null || dateEnd === undefined || dateEnd === '') {
            const objStart = new Date(dateStart);
            const startD = objStart.getDate();
            const startM = objStart.getMonth();
            const startY = objStart.getFullYear();

            setDate(startD + ' ' + thaiMonths[startM] + ' ' + (startY + 543));
        }
        else {
            const objStart = new Date(dateStart);
            const startD = objStart.getDate();
            const startM = objStart.getMonth();
            const startY = objStart.getFullYear();
        
            const objEnd = new Date(dateEnd);
            const endD = objEnd.getDate();
            const endM = objEnd.getMonth();
            const endY = objEnd.getFullYear();

            if(startY !== endY) {
                setDate(startD + ' ' + thaiMonths[startM] + ' ' + (startY + 543) + ' - ' + endD + ' ' + thaiMonths[endM] + ' ' + (endY + 543));
            }
            else if (startM !== endM) {
                setDate(startD + ' ' + thaiMonths[startM] + ' - ' + endD + ' ' + thaiMonths[endM] + ' ' + (endY + 543))
            }
            else {
                setDate(startD + ' - ' + endD + ' ' + thaiMonths[startM] + ' ' + (startY + 543));
            }
        }
    }

    return (
        <div className='relative w-screen h-screen z-0'>
            { /* Content of Home */ }
            <div className='w-screen h-screen overflow-y-scroll'>
                <Header/>

                <div className='relative w-full h-60'>
                    <div className='absolute top-0 left-0 w-full h-14 bg-black'>
                        <div className='relative w-full h-full'>
                            <div className='absolute top-0 left-0 w-full h-full flex justify-start items-center gap-5 z-20'>
                                <Link to={'/'}><p className='notosan-reg text-white ml-10 -translate-y-0.5 cursor-pointer'>หน้าแรก</p></Link>
                                <p className='notosan-reg text-white -translate-y-0.5'>/</p>
                                <Link to={'/activities'}><p className='notosan-reg text-white -translate-y-0.5 cursor-pointer'>กิจกรรม</p></Link>
                                <p className='notosan-reg text-white -translate-y-0.5'>/</p>
                                <p className='notosan-reg text-white -translate-y-0.5'>{title}</p>
                            </div>

                            <div className='absolute top-0 left-0 w-full h-full bg-gradient-to-r from-black opacity-80 z-10 py-3 px-4'></div>
                        </div>
                    </div>

                    <div className='absolute bottom-0 left-0 w-full h-4/6 bg-gradient-to-t from-black opacity-30 z-10'></div>
                    <img src={cover} className='relative w-full h-full object-cover'/>
                </div>

                <div className='mx-10 sm:mx-20 xl:mx-48 pt-20 pb-28'>
                    <div className='w-full h-fit flex flex-col justify-center my-6'>
                        <h3 className='text-web-gold text-3xl sm:text-4xl notosan-med text-center'>กิจกรรมของศูนย์นโยบายและการจัดการสุขภาพ</h3>
                        <img src={title_decorate} className='w-28 h-auto self-center my-4'/>
                    </div>

                    <h3 className='text-web-gold text-3xl sm:text-4xl notosan-med mt-16'>{title}</h3>
                    <p className='notosan-med text-web-black my-4'>ระยะเวลาของกิจกรรม : {date}</p>
                    <div className='w-32 h-1.5 bg-web-gold my-8'></div>

                    <img src={image_cover} className='w-full h-auto object-cover rounded-sm'/>
                </div>

                <Footer form={false}/>
            </div>

            { /* Any modal */ }
        </div>
    )
}

export default Activities_detail