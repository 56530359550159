import React, { useEffect, useState } from 'react';

const KMCard = ({info}) => {
    const [ hover, setHover ] = useState(false);
    const [ str_author, setStr_author ] = useState('');
    const [ str_position, setStr_position ] = useState('');
    const [ str_date, setStr_date ] = useState('');

    const thaiMonths = ["มกราคม","กุมภาพันธ์","มีนาคม","เมษายน","พฤษภาคม","มิถุนายน","กรกฎาคม","สิงหาคม","กันยายน","ตุลาคม","พฤศจิกายน","ธันวาคม"];

    useEffect(() => {
        const each_date = new Date(info.date);
        const each_dateD = each_date.getDate();
        const each_dateM = each_date.getMonth();
        const each_dateY = each_date.getFullYear();

        setStr_author(info.authors);
        setStr_position(info.positions);
        setStr_date(each_dateD + ' ' + thaiMonths[each_dateM] + ' ' + (each_dateY + 543));
    }, [info])

    return (
        <div onMouseEnter={() => setHover(true)} onMouseLeave={() => setHover(false)}
        className='w-full h-full border border-gray-300 rounded-xl cursor-pointer'>
            <div className='relative w-full h-64 rounded-t-xl'>
                <div className='w-full h-full overflow-hidden'>
                    <img src={info.cover} className={'w-full h-full object-cover transition-transform rounded-t-xl duration-300 ' + (hover ? 'scale-125' : 'scale-100')}/>
                </div>

                <div className='absolute top-0 left-0 w-full h-full flex gap-x-2 items-end overflow-visible px-8 z-10'>
                    <div className='w-14 h-14 bg-white rounded-full border-2 border-white translate-y-1/2 overflow-hidden z-10'>
                        <img src={info.head_img} className='w-full h-full object-cover'/>
                    </div>
                </div>
            </div>

            <div className='w-fit h-fit px-8 pt-6 pb-10'>
                <p className='notosan-reg text-web-black-secondary text-sm mt-6'>{str_date}</p>
                <p className={'w-full h-fit notosan-med text-web-black text-base line-clamp-2 text-web-gold text-xl ' + (hover ? 'underline' : '')}>{info.title}</p>

                <div className='w-full h-1.5'></div>

                <p className='notosan-reg text-web-black-secondary text-sm line-clamp-1'>{str_author}</p>
                <p className={'notosan-reg text-sm line-clamp-1 ' + (str_position ? 'text-web-black-secondary/50' : 'text-web-black-secondary/0')}>{str_position}</p>
            </div>
        </div>
    )
}

export default KMCard