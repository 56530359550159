import React, { useEffect, useRef, useState } from 'react';
import KM_paragraph from '../../KM/KM_paragraph';
import Admin_add_button from '../../Components/Button/Admin_add_button';
import Admin_KM_reorderable_list from './Admin_KM_Reorderable_list';
import { motion, useAnimationControls } from "framer-motion";

const Detail_option = ({label, active, disabled, handle_click}) => {
    const [ hover, setHover ] = useState(false);

    return (
        <>
            {
                (disabled) ? 
                    <div className={'w-fit h-fit border rounded-full px-3 py-1.5 cursor-not-allowed bg-gray-100 border-gray-300 text-gray-400'}>{label}</div>
                :
                    <div className={'w-fit h-fit border rounded-full px-3 py-1.5 cursor-pointer transition-color duration-300 ' + ((hover || active) ? 'bg-zinc-700 text-white border-zinc-700' : 'bg-white text-web-black border-gray-300')}
                    onClick={handle_click} onMouseEnter={() => setHover(true)} onMouseLeave={() => setHover(false)}>{label}</div>
            }
        </>
    )
}

const Detail_list_title = ({title}) => {
    const [ hover, setHover ] = useState(false);

    return (
        <div className={'w-full h-fit px-4 py-2 border border-gray-300 rounded-md my-px line-clamp-1 ' + (hover ? 'bg-zinc-700 ' : 'bg-white ')}
        onMouseEnter={() => setHover(true)} onMouseLeave={() => setHover(false)}>
            <p className={'notosan-semi w-full h-fit line-clamp-1 ' + (hover ? 'text-white' : 'text-web-gold')}>{title}</p>
        </div>
    )
}

const Input_Image = ({active, paragraph_index, handle_update_img, handle_update_des}) => {
    const google_drive_format = 'https://drive.google.com/thumbnail?id=IMAGEID&sz=w1200';

    const ref_url = useRef(null);
    const ref_des = useRef(null);

    useEffect(() => {
        if(!active) {
            if(ref_url.current) {
                ref_url.current.value = '';
            }

            if(ref_des.current) {
                ref_des.current.value = '';
            }
        }
    }, [active]);

    return (
        <>
            <p className='notosan-reg text-web-black text-xs'>ลิงก์รูปภาพ</p>
            <input ref={ref_url} className='notosan-reg w-full h-hit border border-gray-300 rounded-sm py-1.5 px-4 my-4 focus:outline focus:outline-mahidol-blue focus:outline-2 focus:outline-offset-1'
            type='text' placeholder='กรุณาระบุ URL ของรูปภาพที่ต้องการเพิ่ม'
            disabled={paragraph_index === -1}
            onChange={(e) => handle_update_img(e.target.value)}/>

            <p className='notosan-reg text-web-black'><u>หมายเหตุ</u> 
            <br/> - แนะนำให้เลือกใช้รูปที่ถ่ายภาพในแนวนอนเพื่อความสวยงามของเว็บไซต์
            <br/> - กรณีต้องการอัพรูปจาก Google Drive ต้องทำการแปลงลิงก์ที่ได้จาก Google Drive เป็น <span className='notosan-reg text-web-gold cursor-pointer'><u>{google_drive_format}</u></span> แล้วเปลี่ยน <span className='notosan-reg text-web-gold'>IMAGEID</span> เป็น id รูปภาพจาก Google Drive</p>

            <p className='notosan-reg text-web-black text-xs mt-4'>คำอธิบายใต้รูปภาพ</p>
            <textarea ref={ref_des}
            className={'notosan-reg w-full h-hit border border-gray-300 rounded-md py-1.5 px-4 py-1 mt-2 focus:outline focus:outline-mahidol-blue focus:outline-2 focus:outline-offset-1 ' + (paragraph_index === -1 ? 'cursor-not-allowed' : '')}
            type='text' rows={4} placeholder={'โปรดระบุคำอธิบายใต้รูปภาพ'}
            disabled={paragraph_index === -1}
            onChange={(e) => handle_update_des(e.target.value)}/>
        </>
    )
}

const Input_Quote = ({active, paragraph_index, handle_update_quote}) => {
    const ref_quote = useRef();

    useEffect(() => {
        if(!active) {
            if(ref_quote.current) {
                ref_quote.current.value = '';
            }
        }
    }, [active]);

    return (
        <>
            <p className='notosan-reg text-web-black text-xs'>รายละเอียด</p>
            <textarea ref={ref_quote}
            className={'notosan-reg w-full h-hit border border-gray-300 rounded-md py-1.5 px-4 py-1 mt-2 focus:outline focus:outline-mahidol-blue focus:outline-2 focus:outline-offset-1'}
            type='text' rows={10} placeholder='โปรดระบุประโยคสำคัญ' 
            disabled={paragraph_index === -1}
            onChange={(e) => handle_update_quote(e.target.value)}/>
        </>
    )
}

const Modal_Admin_KM_add_detail = ({isShow, title, details, handle_cancel, handle_submit}) => {
    const [ modal_details, setModal_details ] = useState([]);
    const [ modal_indent, setModal_indent ] = useState(false);
    const [ paragraph_index, setParagraph_index ] = useState(-1);
    const [ disable_opt_img, setDisable_opt_img ] = useState(true);
    const [ disable_opt_quote, setDisable_opt_quote ] = useState(true);
    const [ disable_opt_indent, setDisable_opt_indent ] = useState(true);
    const [ disable_opt_highlight, setDisable_opt_highlight ] = useState(true);
    const [ disable_opt_url, setDisable_opt_url ] = useState(true);
    const [ mode_add_img, setMode_add_img ] = useState(false);
    const [ mode_add_quote, setMode_add_quote ] = useState(false);
    const [ active_submit_btn, setActive_submit_btn ] = useState(false);

    const ref_text_area = useRef(null);

    useEffect(() => {
        if(isShow) {
            if(details.length > 0) {
                const deepCopy = (arr) => arr.map((item) => ({ ...item }));
                setModal_details(deepCopy(details));
            }
        }
        else {
            setModal_details([]);
            setModal_indent(false);
            setParagraph_index(-1);
            setDisable_opt_img(true);
            setDisable_opt_quote(true);
            setDisable_opt_indent(true);
            setDisable_opt_highlight(true);
            setDisable_opt_url(true);
            setMode_add_img(false);
            setMode_add_quote(false);
            setActive_submit_btn(false);
    
            if(ref_text_area.current) {
                ref_text_area.current.value = '';
            }
        }
    }, [isShow]);

    useEffect(() => {
        if(paragraph_index === -1) {
            if(ref_text_area.current) {
                ref_text_area.current.value = '';
            }

            setModal_indent(true);
            
            setDisable_opt_img(true);
            setDisable_opt_quote(true);
            setDisable_opt_indent(true);
            setDisable_opt_highlight(true);
            setDisable_opt_url(true);
        }
        else {
            const paragraph_indent = modal_details[paragraph_index].indent;
            const paragraph_detail = modal_details[paragraph_index].detail;

            if(ref_text_area.current) {
                ref_text_area.current.value = paragraph_detail ? paragraph_detail : '';
            }

            setModal_indent(paragraph_indent);

            setDisable_opt_img(false);
            setDisable_opt_quote(false);
            setDisable_opt_indent(false);
            setDisable_opt_highlight(false);
            setDisable_opt_url(false);

            setMode_add_img(modal_details[paragraph_index].type === 'image');
            setMode_add_quote(modal_details[paragraph_index].type === 'quote');
        }
    }, [paragraph_index]);

    useEffect(() => {
        //set button
        let active_btn = false;

        if(modal_details.length > 0 && paragraph_index !== -1) {
            for(let i = 0; i < modal_details.length; i++) {
                if(modal_details[i].detail !== null) {
                    active_btn = true;
                    break;
                }
            }
        }

        setActive_submit_btn(active_btn);
    }, [modal_details]);
        
    useEffect(() => {
        //reset all states and components before assign based on mode
        //components related to image and quoat will automatically reset when mode change to false
        //so, do not reset them more, just reset state
        setModal_indent(false);
        setDisable_opt_img(true);
        setDisable_opt_quote(true);
        setDisable_opt_indent(true);
        setDisable_opt_highlight(true);
        setDisable_opt_url(true);

        //set state based on mode
        if(mode_add_img) {
            setModal_indent(false);
            setDisable_opt_img(false);
            setDisable_opt_quote(false);
            setDisable_opt_indent(true);
            setDisable_opt_highlight(true);
            setDisable_opt_url(true);
        }
        else if(mode_add_quote) {
            setModal_indent(false);
            setDisable_opt_img(false);
            setDisable_opt_quote(false);
            setDisable_opt_indent(true);
            setDisable_opt_highlight(false);
            setDisable_opt_url(true);
        }
        else {
            setModal_indent(true);
            setDisable_opt_img(false);
            setDisable_opt_quote(false);
            setDisable_opt_indent(false);
            setDisable_opt_highlight(false);
            setDisable_opt_url(false);
        }

        //activate animation to change mode
        ani_controls_paragraph.start("hidden");
        ani_controls_image.start("hidden");
        ani_controls_quote.start("hidden");

        setTimeout(() => {
            if(mode_add_img) {
                ani_controls_image.start("visible");
            }
            else if(mode_add_quote) {
                ani_controls_quote.start("visible");
            }
            else {
                ani_controls_paragraph.start("visible");
            }
        }, 500);
    }, [mode_add_img, mode_add_quote]);

    const ani_controls_paragraph = useAnimationControls();
    const ani_controls_image = useAnimationControls();
    const ani_controls_quote = useAnimationControls();

    const ani_obj = {
        hidden: {
            opacity: 0,
            transition: { duration: 0.2 }
        },
        visible: {
            opacity: 1,
            transition: { duration: 0.8 }
        }
    }

    return (
        <div className={isShow ? 'block' : 'hidden'}>
            <div className='absolute top-0 left-0 w-screen h-screen flex justify-center items-center z-50'>
                <div className='w-11/12 h-full flex flex-col px-6 sm:px-10 py-10 bg-white rounded-md shadow-md overflow-y-scroll overflow-x-visible'>
                    <p className='notosan-reg text-web-black'>โปรดระบุรายละเอียด (สำหรับ 1 ย่อหน้า)</p>

                    <div className='w-full h-96 grow my-4 grid grid-cols-3 gap-x-5'>
                        <div className='w-full h-full overflow-y-scroll col-span-2'>
                            <p className='notosan-reg text-web-black text-xs'>ตัวอย่าง</p>

                            {
                                (modal_details.length > 0) ?
                                    <div className='w-full h-fit my-4 pr-3'>
                                        <h3 className='text-web-gold text-2xl notosan-med'>{title}</h3>
                                        <KM_paragraph details={modal_details} last={true}/>
                                    </div>
                                :
                                    <p className='notosan-reg text-web-black text-center'>ยังไม่มีตัวอย่าง</p>
                            }
                        </div>

                        <div className='w-full h-full overflow-y-scroll'>
                            <p className='notosan-reg text-web-black text-xs'>รายการ {modal_details.length}</p>

                            <Detail_list_title title={title}/>

                            <Admin_KM_reorderable_list 
                            list={modal_details}
                            paragraph_index={paragraph_index}
                            handle_list_select={(index) => {
                                if(index === -1) {
                                    let prev = [...modal_details];
                                    prev[paragraph_index].type = 'text';
                                    setModal_details(prev);

                                    setMode_add_img(false);
                                    setMode_add_quote(false);
                                }

                                setParagraph_index(index);
                            }}
                            handle_reorder={(reorder_array) => {
                                let result = [];

                                for(let i = 0; i < reorder_array.length; i++) {
                                    modal_details.map(x => {
                                        if(x.paragraph === reorder_array[i].paragraph) {
                                            result.push(x);
                                        }
                                    });
                                }

                                setModal_details(result);
                            }}/>

                            <div className={'w-full h-10 mt-2'}>
                                <Admin_add_button label={'เพิ่มรายการ'} handle_click={() => {
                                    const obj_detail = {
                                        paragraph: (paragraph_index === -1) ? 0 : paragraph_index + 1,
                                        type: 'text',
                                        indent: true,
                                        image: null,
                                        detail: null,
                                    };

                                    let prev = [...modal_details];
                                    prev.push(obj_detail);

                                    setModal_details(prev);
                                    setParagraph_index(prev.length - 1);
                                }}/>
                            </div>
                        </div>
                    </div>

                    <hr className='w-full my-8'/>

                    <div className='w-full h-fit mt-4'>
                        <motion.div
                        className={'w-full ' + ((!mode_add_img && !mode_add_quote) ? 'h-fit' : 'h-0 overflow-hidden')}
                        variants={ani_obj}
                        initial="hidden"
                        animate={ani_controls_paragraph}>
                            <p className='notosan-reg text-web-black text-xs'>รายละเอียด</p>
                            <textarea ref={ref_text_area} 
                            className={'notosan-reg w-full h-hit border border-gray-300 rounded-md py-1.5 px-4 py-1 mt-2 focus:outline focus:outline-mahidol-blue focus:outline-2 focus:outline-offset-1 ' + (modal_details.length === 0 ? 'cursor-not-allowed' : '')}
                            type='text' rows={10} placeholder={(modal_details.length === 0) ? 'โปรดกดปุ่ม เพิ่มรายการ เพื่อให้สามารถกรอกข้อมูลได้' : 'โปรดระบุรายละเอียด (สำหรับ 1 ย่อหน้า)'} 
                            disabled={paragraph_index === -1}
                            onChange={(e) => {
                                if(paragraph_index !== -1) {
                                    let prev = [...modal_details];
                                    prev[paragraph_index].type = 'text';
                                    prev[paragraph_index].indent = modal_indent;
                                    prev[paragraph_index].image = null;
                                    prev[paragraph_index].detail = (e.target.value === '') ? null : e.target.value;
                                    setModal_details(prev);
                                }
                            }}/>
                        </motion.div>

                        <motion.div
                        className={'w-full ' + ((mode_add_img) ? 'h-fit' : 'h-0 overflow-hidden')}
                        variants={ani_obj}
                        initial="hidden"
                        animate={ani_controls_image}>
                            <Input_Image
                            active={mode_add_img}
                            paragraph_index={paragraph_index}
                            handle_update_img={(url) => {
                                let prev = [...modal_details];
                                prev[paragraph_index].type = 'image';
                                prev[paragraph_index].indent = false;
                                prev[paragraph_index].image = url;
                                setModal_details(prev);
                            }}
                            handle_update_des={(des) => {
                                let prev = [...modal_details];
                                prev[paragraph_index].type = 'image';
                                prev[paragraph_index].indent = false;
                                prev[paragraph_index].detail = des;
                                setModal_details(prev);
                            }}/>
                        </motion.div>

                        <motion.div
                        className={'w-full ' + ((mode_add_quote) ? 'h-fit' : 'h-0 overflow-hidden')}
                        variants={ani_obj}
                        initial="hidden"
                        animate={ani_controls_quote}>
                            <Input_Quote 
                            active={mode_add_quote}
                            paragraph_index={paragraph_index}
                            handle_update_quote={(quote) => {
                                let prev = [...modal_details];
                                prev[paragraph_index].type = 'quote';
                                prev[paragraph_index].indent = false;
                                prev[paragraph_index].image = null;
                                prev[paragraph_index].detail = quote;
                                setModal_details(prev);
                            }}/>
                        </motion.div>
                    </div>

                    <div className='w-full h-fit flex justify-end gap-x-1.5 mt-px'>
                        <Detail_option label={'รูปภาพ'} 
                        active={mode_add_img}
                        disabled={disable_opt_img}
                        handle_click={() => {
                            setMode_add_img(!mode_add_img);
                            setMode_add_quote(false);
                        }}/>

                        <Detail_option label={'ประโยคสำคัญ'} 
                        active={mode_add_quote}
                        disabled={disable_opt_quote}
                        handle_click={() => {
                            setMode_add_img(false);
                            setMode_add_quote(!mode_add_quote);
                        }}/>

                        <Detail_option label={'การเยื้อง'} 
                        active={modal_indent}
                        disabled={disable_opt_indent}
                        handle_click={() => {
                            if(paragraph_index !== -1) {
                                const new_indent = !modal_indent;
                                setModal_indent(new_indent);

                                let prev = [...modal_details];
                                prev[paragraph_index].indent = new_indent;
                                setModal_details(prev);
                            }
                        }}/>

                        <Detail_option label={'เพิ่มส่วนไฮไลท์'} 
                        active={false}
                        disabled={disable_opt_highlight}
                        handle_click={() => {
                            let prev = ref_text_area.current.value;
                            prev += "<HL><HL>";
                            ref_text_area.current.value = prev;
                        }}/>

                        <Detail_option label={'เพิ่มลิงก์'} 
                        active={false}
                        disabled={disable_opt_url}
                        handle_click={() => {
                            let prev = ref_text_area.current.value;
                            prev += "<LINK><URL><URL><LINK>";
                            ref_text_area.current.value = prev;
                        }}/>
                    </div>

                    <div className={'flex flex-col-reverse sm:grid sm:grid-cols-2 gap-3 w-full h-auto min-h-12 mt-8'}>
                        <button className='notosan-med w-full h-full bg-gray-300 hover:bg-gray-500 py-3 rounded-md' 
                        onClick={() => handle_cancel()}>ยกเลิก</button>

                        <button type='button' 
                        className={'notosan-med w-full h-full py-3 rounded-md ' + (active_submit_btn ? 'bg-mahidol-blue hover:bg-mahidol-yellow text-white hover:text-web-black cursor-pointer' : 'bg-gray-300 text-gray-500 cursor-not-allowed')}
                        onClick={() => handle_submit(title, modal_details)}>ยืนยัน</button>
                    </div>
                </div>
            </div>

            <div className='absolute top-0 left-0 w-screen h-screen bg-black opacity-50 z-40'></div>
        </div>
    )
}

export default Modal_Admin_KM_add_detail