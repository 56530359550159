import React, { useEffect, useRef, useState } from 'react';

const Modal_add_image = ({isShow, handle_cancel, handle_submit}) => {
    const [ url, setURL ] = useState('');
    const ref = useRef(null);

    const google_drive_format = 'https://drive.google.com/thumbnail?id=IMAGEID&sz=w1000';

    useEffect(() => {
        if(ref.current) {
            setURL('');
            ref.current.value = '';
        }
    }, [isShow]);

    return (
        <div className={isShow ? 'block' : 'hidden'}>
            <div className='absolute top-0 left-0 w-screen h-screen flex justify-center items-center z-50'>
                <div className='w-5/6 lg:w-2/6 h-fit px-6 sm:px-10 py-10 bg-white rounded-md shadow-md'>
                    <p className='notosan-reg text-web-black'>กรุณาระบุ URL ของรูปภาพที่ต้องการเพิ่ม</p>
                    <input className='notosan-reg w-full h-hit border border-gray-300 rounded-sm py-1.5 px-4 my-4 focus:outline focus:outline-mahidol-blue focus:outline-2 focus:outline-offset-1'
                    ref={ref} type='text' placeholder='กรุณาระบุ URL ของรูปภาพที่ต้องการเพิ่ม' onChange={(e) => setURL(e.target.value)}/>
                    
                    <p className='notosan-reg text-web-black'><u>หมายเหตุ</u> 
                    <br/> - แนะนำให้เลือกใช้รูปที่ถ่ายภาพในแนวนอนเพื่อความสวยงามของเว็บไซต์
                    <br/> - กรณีต้องการอัพรูปจาก Google Drive ต้องทำการแปลงลิงก์ที่ได้จาก Google Drive เป็น <span className='notosan-reg text-web-gold cursor-pointer' onClick={() => ref.current.value = google_drive_format}><u>{google_drive_format}</u></span> แล้วเปลี่ยน <span className='notosan-reg text-web-gold'>IMAGEID</span> เป็น id รูปภาพจาก Google Drive</p>

                    <div className={'flex flex-col-reverse sm:grid sm:grid-cols-2 gap-3 w-full h-auto min-h-12 mt-4'}>
                        <button className='notosan-med w-full h-full bg-gray-300 hover:bg-gray-500 py-3 rounded-md' onClick={handle_cancel}>ยกเลิก</button>
                        <button type='button' onClick={() => handle_submit(url)}
                        className={'notosan-med w-full h-full py-3 rounded-md ' + (url.length > 0 ? 'bg-mahidol-blue hover:bg-mahidol-yellow text-white hover:text-web-black cursor-pointer' : 'bg-gray-300 text-gray-500 cursor-not-allowed')}>ยืนยัน</button>
                    </div>
                </div>
            </div>

            <div className='absolute top-0 left-0 w-screen h-screen bg-black opacity-50 z-40'></div>
        </div>
    )
}

export default Modal_add_image