import React, { useEffect, useRef, useState } from 'react';
import Header from '../Components/Header/Header';
import Footer from '../Components/Footer/Footer';
import { Link, useParams } from 'react-router-dom';
import cover from '../../assets/cover/cover_news.png';
import default_items from '../Admin/Admin_KM/KM_list';
import KM_paragraph from './KM_paragraph';
import axios from 'axios';
import { php_list } from '../../php/php_list';

const Author_name = ({info, last}) => {
    const Author_name_with_modal = ({info}) => {
        const [ hover, setHover ] = useState(false);
        const [ position_right, setPosition_right ] = useState(false); //popover is placed on the right, change to bottom when component is place on right of the screen
        const ref = useRef(null);
    
        useEffect(() => {
            const handlePosition = () => {
                if(ref.current) {
                    const rect = ref.current.getBoundingClientRect();
                    const screenWidth = window.innerWidth;
    
                    if(Math.abs(screenWidth - rect.right) < 300) {
                        setPosition_right(false);
                    }
                    else {
                        setPosition_right(true);
                    }
                }
            }
    
            setPosition_right(false);
            handlePosition();
            window.addEventListener('resize', handlePosition);
    
            return () => window.removeEventListener('resize', handlePosition);
        }, []);
    
        return (
            <span className='relative'>
                <span ref={ref} className='inline-block notosan-med text-web-gold cursor-pointer hover:text-web-gold-bold hover:underline'
                onMouseEnter={() => setHover(true)} onMouseLeave={() => setHover(false)}>{info.prefix + info.name}</span>
                
                <div className={'absolute translate-y-full w-fit h-fit ' + (hover ? 'z-10 ' : 'z-0 ') + (position_right ? 'bottom-0 left-0' : 'bottom-0 right-0')}>
                    <div className={'w-72 h-fit bg-white border border-gray-200 drop-shadow-md rounded-md px-4 py-5 mt-2 transition duration-300 ' + (hover ? 'opacity-100' : 'opacity-0')}>
                        <div className='w-14 h-14 min-w-14 rounded-full overflow-hidden'>
                            <img src={info.image} className='w-full h-full object-cover'/>
                        </div>
    
                        <p className='notosan-semi text-web-black my-2'>{info.prefix + info.name}</p>
                        <p className='notosan-reg text-web-black/50 text-xs'>{info.position}</p>
                    </div>
                </div>
            </span>
        )
    }

    return (
        <>
            <Author_name_with_modal info={info}/>

            {
                (last ? null : <span className='notosan-med text-web-gold mr-3'>,</span>)
            }
        </>
    )
}

const KM_detail = () => {
    const {id} = useParams();

    const [ res_title, setRes_title ] = useState({});
    const [ res_author, setRes_author ] = useState({});
    const [ res_detail, setRes_detail ] = useState({});

    const [ image_cover, setImageCover ] = useState(null);
    const [ date, setDate ] = useState(null);
    const [ title, setTitle ] = useState('');
    const [ head_author, setHead_author ] = useState([]); //header can contain only 1 obj, using array to able to check condition if empty or not
    const [ authors, setAuthors ] = useState([]); //authors can contain multiple obj
    const [ head_img, setHead_img ] = useState('');
    const [ author_id, setAuthor_id ] = useState('');
    const [ details, setDetails ] = useState([]);

    useEffect(() => {
        let abortcontroller = new AbortController();

        const fetchData = async () => {
            try {
                const requests = [
                    axios.get(php_list.php_km_read_title_id, { 
                        params: { id: id },
                        signal: abortcontroller.signal 
                    }),
                    axios.get(php_list.php_km_read_author_id, { 
                        params: { id: id },
                        signal: abortcontroller.signal 
                    }),
                    axios.get(php_list.php_km_read_detail_id, { 
                        params: { id: id },
                        signal: abortcontroller.signal 
                    }),
                ];

                const index_title = 0;
                const index_author = 1;
                const index_detail = 2;
                const responses = await Promise.all(requests);

                setRes_title(responses[index_title]);
                setRes_author(responses[index_author]);
                setRes_detail(responses[index_detail]);
            }
            catch (error) {
                if (axios.isCancel(error)) {
                    console.log('Request canceled:', error.message);
                } else {
                    console.error('Error fetching data:', error);
                }
            }
        };

        fetchData();

        return () => abortcontroller.abort();
    }, []);

    useEffect(() => {
        if(Object.keys(res_title).length > 0) {
            setTitle(res_title.data.title);
            setImageCover(res_title.data.cover);

            const date = new Date(res_title.data.date);
            const options = { year: 'numeric', month: 'long', day: 'numeric', timeZone: 'Asia/Bangkok' };
            const formattedDate = date.toLocaleDateString('th-TH', options);
            setDate(formattedDate);
        }
    }, [res_title]);

    useEffect(() => {
        if(Object.keys(res_author).length > 0) {
            let result_head = [];
            let result_normal = [];

            for(let i = 0; i < res_author.data.results.length; i++) {
                const info = res_author.data.results[i];
                const head = parseInt(info.head);
                
                if(head === 1) {
                    result_head.push(info);
                }
                else {
                    result_normal.push(info);
                }
            }

            setHead_author(result_head);
            setAuthors(result_normal);
        }
    }, [res_author]);

    useEffect(() => {
        if(Object.keys(res_detail).length > 0) {
            const all_details = res_detail.data.results;
            const MaxIndex = Math.max(...all_details.map(x => parseInt(x.topicIndex))); //the highest topicIndex to determine number of loop
            let result = [];

            for(let i = 0; i <= MaxIndex; i++) {
                //get topic
                let topic = '';

                for(let j = 0; j < all_details.length; j++) {
                    const info_topic = all_details[j].topic;
                    const info_topicIndex = parseInt(all_details[j].topicIndex);

                    if(info_topicIndex === i) {
                        topic = info_topic;
                        break;
                    }
                }

                //get number of paragraph within each topic
                let paragraphs = [];

                for(let j = 0; j < all_details.length; j++) {
                    const info_topicIndex = parseInt(all_details[j].topicIndex);
                    const paragraph = parseInt(all_details[j].paragraph);

                    if(info_topicIndex === i) {
                        paragraphs.push(paragraph);
                    }
                }

                //get max paragraph of each topic
                const MaxParagraph = Math.max(...paragraphs);

                //get detail
                let result_detail = [];

                for(let j = 0; j <= MaxParagraph; j++) {
                    for(let k = 0; k < all_details.length; k++) {
                        const info_topicIndex = parseInt(all_details[k].topicIndex);
    
                        const paragraph = parseInt(all_details[k].paragraph);
                        const type = all_details[k].type;
                        const indent = (parseInt(all_details[k].indent) === 1);
                        const image = all_details[k].image;
                        const detail = all_details[k].detail;
    
                        if(info_topicIndex === i && paragraph === j) {
                            result_detail.push({
                                paragraph: paragraph,
                                type: type,
                                indent: indent,
                                image: image,
                                detail: detail,
                            });
                        }
                    }
                }

                result.push({
                    topic: topic,
                    detail: result_detail,
                })
            }

            setDetails(result);
        }
    }, [res_detail]);

    return (
        <div className='relative w-screen h-screen z-0'>
            { /* Content of Home */ }
            <div className='w-screen h-screen overflow-y-scroll'>
                <Header/>

                <div className='relative w-full h-60'>
                    <div className='absolute top-0 left-0 w-full h-14 bg-black'>
                        <div className='relative w-full h-full'>
                        <div className='absolute top-0 left-0 w-full h-full flex justify-start items-center gap-5 z-20'>
                            <Link to={'/'}><p className='notosan-reg text-white ml-10 -translate-y-0.5 cursor-pointer'>หน้าแรก</p></Link>
                            <p className='notosan-reg text-white -translate-y-0.5'>/</p>
                            <Link to={'/km'}><p className='notosan-reg text-white -translate-y-0.5 cursor-pointer'>การจัดการความรู้</p></Link>
                            <p className='notosan-reg text-white -translate-y-0.5'>/</p>
                            <p className='notosan-reg text-white -translate-y-0.5 line-clamp-1'>{title}</p>
                        </div>

                        <div className='absolute top-0 left-0 w-full h-full bg-gradient-to-r from-black opacity-80 z-10 py-3 px-4'></div>
                        </div>
                    </div>

                    <div className='absolute bottom-0 left-0 w-full h-4/6 bg-gradient-to-t from-black opacity-30 z-10'></div>
                    <img src={cover} className='relative w-full h-full object-cover'/>
                </div>

                <div className='mx-10 sm:mx-20 xl:mx-48 pt-20 pb-28'>
                    <img src={image_cover} className='w-full h-auto min-h-96 object-cover rounded-xl'/>

                    <h3 className='text-web-gold text-3xl sm:text-4xl notosan-med mt-16'>{title}</h3>

                    <p className='w-full h-fit my-4'>
                        <span className='notosan-med text-web-gold'>โพสต์วันที่ : {date}</span>
                        <span className='notosan-med text-web-gold mx-4'>|</span>

                        {
                            head_author.map((x, i) => {
                                return (
                                    <Author_name key={i} info={x} last={!authors.length > 0}/>
                                )
                            })
                        }

                        {
                            authors.map((x, i) => {
                                return (
                                    <Author_name key={i} info={x} last={i === authors.length - 1}/>
                                )
                            })
                        }
                    </p>

                    <div className='w-32 h-1.5 bg-web-gold my-8'></div>

                    {
                        details.map((x, i) => {
                            return (
                                <div key={i} className='w-full h-fit mt-16 mb-6'>
                                    <h3 className='text-web-gold text-2xl notosan-med'>{x.topic}</h3>
                                    <KM_paragraph details={x.detail} last={i === details.length - 1}/>
                                </div>
                            )
                        })
                    }
                </div>

                <Footer form={false}/>
            </div>

            { /* Any modal */ }
        </div>
    )
}

export default KM_detail