import React, { useEffect, useState } from 'react';

const Quote = ({quote}) => {
  const [ texts, setTexts ] = useState([]);

  useEffect(() => {
    const result = quote.split("<HL>");
    setTexts(result);
  }, [quote]);

  return (
    <h3 className='text-web-black text-2xl sm:text-3xl lg:text-4xl notosan-med text-center' style={{lineHeight: 1.5}}>
      <span className='pr-3 inline-block -translate-y-1 lg:-translate-y-3'>
        <box-icon type='solid' name='quote-alt-left'></box-icon>
      </span>

      {
        texts.map((x, i) => {
          return (
            (i%2 === 0) ?
              <span key={i}>{x}</span>
            :
              <span key={i} className='text-web-gold'>{x}</span>
          )
        })
      }

      <span className='pl-3 inline-block -translate-y-1 sm:-translate-y-3'>
        <box-icon name='quote-alt-right' type='solid' ></box-icon>
      </span>
    </h3>                            
  )
}

export default Quote