import React from 'react';
import mahidol_logo from '../../assets/association/mahidol.png';
import public_logo from '../../assets/association/publichealth.png';
import moralcenter_logo from '../../assets/association/moralcenter.png';
import obec_logo from '../../assets/association/obec.png';
import nationalhealth_logo from '../../assets/association/nationalhealth.png';
import icuddr_logo from '../../assets/association/icuddr.png';
import title_decorate from '../../assets/decorate/title_decorate.svg';
import { motion } from "framer-motion";

const Logo = (image) => {
    return (
        <img src={image} className='w-24 sm:w-28 h-24 sm:h-28'/>
    )
}

const HomeAssociation = () => {
    const logo_class = 'w-24 sm:w-28 xl:w-36 h-24 sm:h-28 xl:h-36';
    
    const ani_obj = {
        initial: { 
          opacity: 0,
          y: 10
        },
        animate: (index) => ({ 
          opacity: 1,
          y: 0,
          transition: { delay: 0.2 * index, duration: 2 }
        })
    }

    return (
        <div className='w-full h-fit pb-0 sm:pb-10'>
            <div className='w-full h-fit flex flex-col justify-center my-6'>
                <h3 className='text-web-gold text-3xl sm:text-4xl notosan-med text-center mx-10 sm:mx-20 xl:mx-48'>ภายใต้ความร่วมมือกับองค์กรต่างๆ</h3>
                <img src={title_decorate} className='w-28 h-auto self-center my-4'/>
            </div>

            <div className='w-full h-fit flex justify-center my-4 sm:my-10'>
                <div className='w-fit h-fit grid grid-cols-2 sm:grid-cols-3 xl:grid-cols-5 justify-center gap-12 sm:gap-10 md:gap-16 mx-10 sm:mx-0'>
                    <motion.div
                    className='w-full sm-w-fit h-full sm:h-fit flex sm:block justify-center'
                    variants={ani_obj}
                    initial= "initial"
                    whileInView="animate"
                    viewport={{once: true}}
                    custom={0}>
                        <img src={mahidol_logo} className={logo_class}/>
                    </motion.div>

                    <motion.div
                    className='w-full sm-w-fit h-full sm:h-fit flex sm:block justify-center'
                    variants={ani_obj}
                    initial= "initial"
                    whileInView="animate"
                    viewport={{once: true}}
                    custom={1}>
                        <img src={public_logo} className={logo_class}/>
                    </motion.div>

                    <motion.div
                    className='w-full sm-w-fit h-full sm:h-fit flex sm:block justify-center'
                    variants={ani_obj}
                    initial= "initial"
                    whileInView="animate"
                    viewport={{once: true}}
                    custom={3}>
                        <img src={obec_logo} className={logo_class}/>
                    </motion.div>

                    <motion.div
                    className='w-full sm-w-fit h-full sm:h-fit block sm:hidden xl:block justify-center'
                    variants={ani_obj}
                    initial= "initial"
                    whileInView="animate"
                    viewport={{once: true}}
                    custom={0}>
                        <img src={nationalhealth_logo} className={logo_class}/>
                    </motion.div>

                    <motion.div
                    className='w-full sm-w-fit h-full sm:h-fit block sm:hidden xl:block justify-center'
                    variants={ani_obj}
                    initial= "initial"
                    whileInView="animate"
                    viewport={{once: true}}
                    custom={1}>
                        <img src={icuddr_logo} className={logo_class}/>
                    </motion.div>
                </div>
            </div>

            <div className='w-full h-fit hidden sm:flex xl:hidden justify-center'>
                <div className='w-fit h-fit grid grid-cols-2 gap-12 sm:gap-10 md:gap-16'>
                    <motion.div
                    variants={ani_obj}
                    initial= "initial"
                    whileInView="animate"
                    viewport={{once: true}}
                    custom={0}>
                        <img src={nationalhealth_logo} className={logo_class}/>
                    </motion.div>

                    <motion.div
                    variants={ani_obj}
                    initial= "initial"
                    whileInView="animate"
                    viewport={{once: true}}
                    custom={1}>
                        <img src={icuddr_logo} className={logo_class}/>
                    </motion.div>
                </div>
            </div>
        </div>
    )
}

export default HomeAssociation