import React from 'react';
import Header from '../Components/Header/Header';
import Footer from '../Components/Footer/Footer';
import cover from '../../assets/cover/cover_news.png';
import title_decorate from '../../assets/decorate/title_decorate.svg';
import { Link } from 'react-router-dom';

import artit from '../../assets/staff/อาทิตย์ อังกานนท์.png';
import noppawan from '../../assets/staff/นพวรรณ เปียซื่อ.png';
import pornchai from '../../assets/staff/พรชัย สิมะโรจน์.png';
import surasak from '../../assets/staff/สุรศักดิ์ กันตชูเวสศิริ.png';
import piyasiri from '../../assets/staff/ปรียาสิริ วิฑูรชาติ2.png';
import pichai from '../../assets/staff/พิชัย จันทร์ศรีวงศ์.png';
import kwanchai from '../../assets/staff/ขวัญชัย ไพโรจน์สกุล.png';
import prapapan from '../../assets/staff/ประภาพรรณ จูเจริญ.png';
import witt from '../../assets/staff/วิชช์ เกษมทรัพย์.png';
import pusit from '../../assets/staff/ภูษิต ประคองสาย.png';
import suparee from '../../assets/staff/สุภารี วิศวพิพัฒน์ บุญมานันท์.png';
import somsak from '../../assets/staff/สมศักดิ์ ชุณหรัศมิ์.png';

import view from '../../assets/staff/พี่วิว.png';
import oat from '../../assets/staff/พี่โอ๊ต.png';
import tui from '../../assets/staff/พี่ตุ๊ย.png';
import oii from '../../assets/staff//พี่อิ๋ว.png';
import a from '../../assets/staff/เอ.png';

const Staff_Frame = ({name, img, position, position2}) => {
    return (
        <div className='w-full h-full'>
            <div className='w-full h-fit flex justify-center'>
                <img src={img} className='w-48 h-48 object-cover rounded-full border-4 border-web-gold'/>
            </div>

            <p className='notosan-semi text-web-gold text-xl text-center mt-4'>{name}</p>
            <p className='notosan-reg text-web-black text-center'>{position}</p>

            {
                (position2) ? <p className='notosan-reg text-web-black text-center'>{position2}</p> : null
            }
        </div>
    )
}

const Team = () => {
    const committees = [
        {
            name: 'ศ.เกียรติคุณ นพ.พรชัย สิมะโรจน์',
            img: pornchai,
            position: 'รักษาการณ์แทนหัวหน้าศูนย์ฯ',
            position2: null,
        },
        {
            name: 'รศ.นพ.สุรศักดิ์ กันตชูเวสศิริ',
            img: surasak,
            position: 'รักษาการณ์แทนรองหัวหน้าศูนย์ฯ',
            position2: null,
        },
        {
            name: 'ดร.นพ.ภูษิต ประคองสาย',
            img: pusit,
            position: 'รักษาการณ์แทนรองหัวหน้าศูนย์ฯ',
            position2: null,
        },
        {
            name: 'ผศ.ดร.ปรียาสิริ วิฑูรชาติ',
            img: piyasiri,
            position: 'รักษาการณ์แทนรองหัวหน้าศูนย์ฯ',
            position2: null,
        },
        {
            name: 'นพ.พิชัย จันทร์ศรีวงศ์',
            img: pichai,
            position: 'รักษาการณ์แทนรองหัวหน้าศูนย์ฯ',
            position2: null,
        },
        {
            name: 'รศ.นพ.ขวัญชัย ไพโรจน์สกุล',
            img: kwanchai,
            position: 'รักษาการณ์แทนผู้ช่วยหัวหน้าศูนย์ฯ',
            position2: null,
        },
        {
            name: 'ผศ.ดร.สุภารี วิศวพิพัฒน์ บุญมานันท์',
            img: suparee,
            position: 'รักษาการณ์แทนผู้ช่วยหัวหน้าศูนย์ฯ',
            position2: null,
        },
        {
            name: 'ผศ.ดร.ประภาพรรณ จูเจริญ',
            img: prapapan,
            position: 'ที่ปรึกษาด้านนโยบายลดอุปสงค์การเสพติด',
            position2: null,
        },
        {
            name: 'ผศ.ดร.นพ.วิชช์ เกษมทรัพย์',
            img: witt,
            position: 'ที่ปรึกษาด้านการขับเคลื่อนนโยบายสุขภาพ',
            position2: null,
        },
        {
            name: 'นพ.สมศักดิ์ ชุณหรัศมิ์',
            img: somsak,
            position: 'ที่ปรึกษาด้านการขับเคลื่อนนโยบายสุขภาพ',
            position2: null,
        },
    ]

    const staffs = [
        {
            name: 'นายดุษฎี มณีสุวรรณรัตน์',
            img: oat,
            position: 'นักบริหารงานวิจัย',
            position2: null,
        },
        {
            name: 'น.ส.วิว วงศ์ลดารมย์',
            img: view,
            position: 'ผู้ช่วยวิจัย',
            position2: null,
        },
        {
            name: 'นายธนะศักดิ์ ตันติชัยยกุล',
            img: a,
            position: 'นักวิชาการโสตทัศนศึกษา',
            position2: null,
        },
        {
            name: 'น.ส.สุรัญญา ไวคุณา',
            img: tui,
            position: 'เจ้าหน้าที่บริหารงานทั่วไป',
            position2: null,
        },
        {
            name: 'น.ส.ธีรนุช แสงทอง',
            img: oii,
            position: 'เจ้าหน้าที่บริหารงานทั่วไป',
            position2: null,
        },
    ]

    return (
        <div className='relative w-screen h-screen z-0'>
            { /* Content of Home */ }
            <div className='w-screen h-screen overflow-y-scroll'>
                <Header/>

                <div className='relative w-full h-60'>
                    <div className='absolute top-0 left-0 w-full h-14 bg-black'>
                        <div className='relative w-full h-full'>
                            <div className='absolute top-0 left-0 w-full h-full flex justify-start items-center gap-5 z-20'>
                                <Link to={'/'}><p className='notosan-reg text-white ml-10 -translate-y-0.5 cursor-pointer'>หน้าแรก</p></Link>
                                <p className='notosan-reg text-white -translate-y-0.5'>/</p>
                                <p className='notosan-reg text-white -translate-y-0.5'>บุคลากรของศูนย์ฯ</p>
                            </div>

                            <div className='absolute top-0 left-0 w-full h-full bg-gradient-to-r from-black opacity-80 z-10 py-3 px-4'></div>
                        </div>
                    </div>

                    <div className='absolute bottom-0 left-0 w-full h-4/6 bg-gradient-to-t from-black opacity-30 z-10'></div>
                    <img src={cover} className='relative w-full h-full object-cover'/>
                </div>

                <div className='mx-0 sm:mx-20 xl:mx-48 py-14 sm:py-28'>
                    <div className='w-full h-fit flex flex-col justify-center my-6'>
                        <h3 className='text-web-gold text-3xl sm:text-4xl notosan-med text-center'>คณะผู้บริหารและบุคลากร</h3>
                        <h3 className='text-web-gold text-3xl sm:text-4xl notosan-med text-center my-3'>ศูนย์นโยบายและการจัดการสุขภาพ</h3>
                        <img src={title_decorate} className='w-28 h-auto self-center my-4'/>
                    </div>

                    <div className='mx-10 md:mx-20 xl:mx-32 flex justify-center'>
                        <Staff_Frame img={artit} name={'ศ.คลินิก นพ.อาทิตย์ อังกานนท์'} position={'คณบดีคณะแพทยศาสตร์'} position2={'โรงพยาบาลรามาธิบดี มหาวิทยาลัยมหิดล'}/>
                    </div>

                    <div className='mx-10 md:mx-20 2xl:mx-32'>
                        <div className='w-full h-fit flex flex-col justify-center mt-20 mb-12'>
                            <img src={title_decorate} className='w-28 h-auto self-center my-4'/>
                        </div>

                        <div className='mx-10 md:mx-20 xl:mx-32 flex justify-center'>
                            <Staff_Frame img={noppawan} name={'ศ.ดร.นพวรรณ เปียซื่อ'} position={'รองคณบดี ฝ่ายสร้างเสริมสุขภาพ'} position2={''}/>
                        </div>

                        <div className='w-full h-fit flex flex-col justify-center mt-20 mb-12'>
                            <img src={title_decorate} className='w-28 h-auto self-center my-4'/>
                        </div>

                        <div className='w-full h-fit grid grid-cols-1 sm:grid-cols-2 lg:grid-cols-3 xl:grid-cols-4 gap-5'>
                            {
                                committees.map((x, i) => {
                                    return (
                                        <Staff_Frame key={i} img={x.img} name={x.name} position={x.position} position2={x.position2}/>
                                    )
                                })
                            }
                        </div>
                    </div>

                    <div className='mx-10 md:mx-20 2xl:mx-32'>
                        <div className='w-full h-fit flex flex-col justify-center mt-20 mb-12'>
                            <h3 className='text-web-gold text-3xl sm:text-4xl notosan-med text-center my-3'>บุคลากรของศูนย์นโยบายและการจัดการสุขภาพ</h3>
                            <img src={title_decorate} className='w-28 h-auto self-center my-4'/>
                        </div>

                        <div className='hidden'>นายแพทย์สมศักดิ์ ชุณหรัศมิ์</div>

                        <div className='w-full h-fit hidden xl:grid grid-cols-1 sm:grid-cols-2 lg:grid-cols-3 gap-5'>
                            {
                                staffs.map((x, i) => {
                                    if(i < 3) {
                                        return (
                                            <Staff_Frame key={i} img={x.img} name={x.name} position={x.position} position2={x.position2}/>
                                        )
                                    }
                                })
                            }
                        </div>

                        <div className='w-full h-fit hidden xl:flex justify-center mt-10'>
                            {
                                staffs.map((x, i) => {
                                    if(i >= 3) {
                                        return (
                                            <Staff_Frame key={i} img={x.img} name={x.name} position={x.position} position2={x.position2}/>
                                        )
                                    }
                                })
                            }
                        </div>

                        <div className='w-full h-fit grid xl:hidden grid-cols-1 sm:grid-cols-2 lg:grid-cols-3 gap-5'>
                            {
                                staffs.map((x, i) => {
                                    return (
                                        <Staff_Frame key={i} img={x.img} name={x.name} position={x.position} position2={x.position2}/>
                                    )
                                })
                            }
                        </div>
                    </div>
                </div>

                <Footer form={false}/>
            </div>

            { /* Any modal */ }
        </div>
    )
}

export default Team