import React, { useEffect, useState } from 'react';
import { motion, useAnimationControls } from "framer-motion";

//custom relates to motion.div to define delay of card animation at first
const HomeTestimonial_Card = ({info, custom}) => {
    const [ hover, setHover ] = useState(false);
    const image_controls = useAnimationControls();
    const text_controls = useAnimationControls();

    const animationVariants_image = {
        hidden: {
            y: '100%',
        },
        visible: {
            y: 0,
        }
    }

    const animationVariants_text = {
        hidden: {
            opacity: 0,
            x: "5%",
        },
        visible: (index) => ({
            opacity: 100,
            x: 0,
            transition: {delay: index * 0.25, duration: 0.5},
        })
    }

    useEffect(() => {
        image_controls.start(hover ? 'visible' : 'hidden');
        text_controls.start(hover ? 'visible' : 'hidden');
    }, [hover]);

    return (
        <motion.div 
        initial={{opacity: 0, y: 10}}
        whileInView={{opacity: 1, y: 0, transition: {duration: 1, delay: custom * 0.2}}}
        viewport={{once: true}}
        className='relative w-full h-full max-h-80 row-span-3 rounded-sm overflow-hidden cursor-pointer'
        onMouseEnter={() => setHover(true)} onMouseLeave={() => setHover(false)}>
            <div className='absolute bottom-0 left-0 mx-6 my-6 z-20'>
                <motion.div
                variants={animationVariants_text}
                initial="hidden"
                animate={text_controls}
                custom={1}>
                    <p className='notosan-med text-white line-clamp-5'>{info.title}</p>
                </motion.div>
                
                <motion.div
                className='mt-2'
                variants={animationVariants_text}
                initial="hidden"
                animate={text_controls}
                custom={2}>
                    <p className='notosan-light text-white text-xs line-clamp-2'>{info.founder.join(', ')}</p>
                </motion.div>
            </div>

            <motion.div className='absolute top-0 left-0 w-full h-full bg-black opacity-70 z-10'
            variants={animationVariants_image}
            initial="hidden"
            animate={image_controls}/>

            <img src={info.image} className='w-full h-full min-h-80 object-cover rounded-sm'/>
        </motion.div>
    )
}

export default HomeTestimonial_Card