import React, { useEffect, useState } from 'react';
import { Link, useParams } from 'react-router-dom';
import Header from '../Components/Header/Header';
import Footer from '../Components/Footer/Footer';
import cover from '../../assets/cover/cover_news.png';
import axios from 'axios';
import { php_list } from '../../php/php_list';

const News_detail = () => {
    const {id} = useParams();

    const [ image_cover, setImageCover ] = useState(null);
    const [ date, setDate ] = useState(null);
    const [ title, setTitle ] = useState('');
    const [ details, setDetails ] = useState([]);
    const [ relatedImgs, setRelatedImgs ] = useState([]);

    useEffect(() => {
        let abortcontroller = new AbortController();

        axios.get(php_list.php_news_read_id, {
            params: {
                id: id
            },
            signal: abortcontroller.signal
        })
        .then((response) => {
            // Handle the success response
            console.log(response.data);

            if (response.data.success) {
                setImageCover(response.data.cover);
                setTitle(response.data.title);

                const date = new Date(response.data.date);
                const options = { year: 'numeric', month: 'long', day: 'numeric', timeZone: 'Asia/Bangkok' };
                const formattedDate = date.toLocaleDateString('th-TH', options);
                setDate(formattedDate);

                setDetails(response.data.details);
                
                let imgs = [];
                response.data.relatedImg.map((x, i) => {
                    imgs.push(x.relatedImg);
                });

                setRelatedImgs(imgs);
            } 
            else {
                alert('Error loading data: ' + response.data.message);
            }
        })
        .catch((error) => {
            console.log(error);
        });

        return () => abortcontroller.abort();
    }, []);

    return (
        <div className='relative w-screen h-screen z-0'>
            { /* Content of Home */ }
            <div className='w-screen h-screen overflow-y-scroll'>
                <Header/>

                <div className='relative w-full h-60'>
                    <div className='absolute top-0 left-0 w-full h-14 bg-black'>
                        <div className='relative w-full h-full'>
                            <div className='absolute top-0 left-0 w-full h-full flex justify-start items-center gap-5 z-20'>
                                <Link to={'/'}><p className='notosan-reg text-white ml-10 -translate-y-0.5 cursor-pointer'>หน้าแรก</p></Link>
                                <p className='notosan-reg text-white -translate-y-0.5'>/</p>
                                <Link to={'/news'}><p className='notosan-reg text-white -translate-y-0.5 cursor-pointer'>ข่าวสารองค์กร</p></Link>
                                <p className='notosan-reg text-white -translate-y-0.5'>/</p>
                                <p className='notosan-reg text-white -translate-y-0.5 line-clamp-1'>{title}</p>
                            </div>

                            <div className='absolute top-0 left-0 w-full h-full bg-gradient-to-r from-black opacity-80 z-10 py-3 px-4'></div>
                        </div>
                    </div>

                    <div className='absolute bottom-0 left-0 w-full h-4/6 bg-gradient-to-t from-black opacity-30 z-10'></div>
                    <img src={cover} className='relative w-full h-full object-cover'/>
                </div>

                <div className='mx-10 sm:mx-20 xl:mx-48 pt-20 pb-28'>
                    <img src={image_cover} className='w-full h-96 sm:h-auto object-cover rounded-sm'/>
                    <h3 className='text-web-gold text-3xl sm:text-4xl notosan-med mt-16'>{title}</h3>

                    <div className='block sm:flex gap-5 my-4'>
                        <p className='hidden sm:block notosan-med text-web-gold'>ข่าวสารองค์กร</p>
                        <p className='hidden sm:block notosan-med text-web-gold'>|</p>

                        <p className='notosan-med text-web-gold'>โพสต์วันที่ : {date}</p>
                    </div>

                    <div className='w-32 h-1.5 bg-web-gold my-8'></div>

                    <div className='w-full h-fit mt-14'>
                        {
                            details.map((x, i) => {
                                return (
                                    <p key={i} className='notosan-reg text-web-black indent-16 my-8'>{x.detail}</p>
                                );
                            })
                        }
                    </div>

                    <h3 className='text-web-gold text-3xl sm:text-4xl notosan-med mt-16'>รูปภาพที่เกี่ยวข้อง</h3>
                    <div className='w-32 h-1.5 bg-web-gold my-8'></div>

                    <div className='w-full h-fit grid grid-cols-1 sm:grid-cols-2 gap-5 mt-8 sm:mt-12'>
                        {
                            relatedImgs.map((x, i) => {
                                return (
                                    <Link key={i} to={x} target="_blank" rel="noopener noreferrer">
                                        <img src={x} className='w-full h-60 sm:h-full object-cover rounded-sm'/>
                                    </Link>
                                );
                            })
                        }
                    </div>
                </div>

                <Footer form={false}/>
            </div>

            { /* Any modal */ }
        </div>
    )
}

export default News_detail