const website = 'https://wandertestsite.com/';
const php_news = 'php_news/';
const php_activity = 'php_activity/';
const php_testimonials = 'php_testimonials/';
const php_km = 'php_km/';

export const php_list = {
    php_news_write: website + php_news + 'php_news_write.php',
    php_news_read_all: website + php_news + 'php_news_read_all.php',
    php_news_read_id: website + php_news + 'php_news_read_id.php',
    php_news_update: website + php_news + 'php_news_update.php',
    php_news_delete: website + php_news + 'php_news_delete.php',

    php_activity_write: website + php_activity + 'php_activity_write.php',
    php_activity_read_all: website + php_activity + 'php_activity_read_all.php',
    php_activity_read_id: website + php_activity + 'php_activity_read_id.php',
    php_activity_update: website + php_activity + 'php_activity_update.php',
    php_activity_delete: website + php_activity + 'php_activity_delete.php',

    php_testimonials_write: website + php_testimonials + 'php_testimonials_write.php',
    php_testimonials_write_timeline_detail: website + php_testimonials + 'php_testimonials_write_timeline_detail.php',
    php_testimonials_read_all: website + php_testimonials + 'php_testimonials_read_all.php',
    php_testimonials_read_id_url: website + php_testimonials + 'php_testimonials_read_id_url.php',
    php_testimonials_read_id_des: website + php_testimonials + 'php_testimonials_read_id_des.php',
    php_testimonials_read_id_timeline: website + php_testimonials + 'php_testimonials_read_id_timeline.php',
    php_testimonials_delete: website + php_testimonials + 'php_testimonials_delete.php',
    php_testimonials_delete_timeline_detail: website + php_testimonials + 'php_testimonials_delete_timeline_detail.php',
    php_testimonials_update: website + php_testimonials + 'php_testimonials_update.php',
    php_testimonials_update_favorite: website + php_testimonials + 'php_testimonials_update_favorite.php',

    php_km_write: website + php_km + 'php_km_write.php',
    php_km_write_detail: website + php_km + 'php_km_write_detail.php',
    php_km_write_author: website + php_km + 'php_km_write_author.php',
    php_km_write_author_link: website + php_km + 'php_km_write_author_link.php',
    php_km_read_author: website + php_km + 'php_km_read_author.php',
    php_km_read_title: website + php_km + 'php_km_read_title.php',
    php_km_read_title_id: website + php_km + 'php_km_read_title_id.php',
    php_km_read_author_id: website + php_km + 'php_km_read_author_id.php',
    php_km_read_detail_id: website + php_km + 'php_km_read_detail_id.php',
    php_km_update_author: website + php_km + 'php_km_update_author.php',
    php_km_update_author_to_delete: website + php_km + 'php_km_update_author_to_delete.php',
    php_km_update: website + php_km + 'php_km_update.php',
    php_km_delete: website + php_km + 'php_km_delete.php',
}